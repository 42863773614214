import React, { useEffect, useState } from "react";
import {
  Dialog,
  Typography,
  DialogActions,
  useTheme,
  DialogContent,
  Button,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Tooltip,
  DialogTitle,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import LoadPatientRecordsFromCSV from "../CSVParser";
// import { usePapaParse } from "react-papaparse";
import Notify from "../../../Components/Notification";

const useStyles = makeStyles((theme) => ({
  loadDialog: {
    width: "100%",
    maxWidth: 600,
  },
  listItem: {},
  dialogPaper: {
    width: 600,
  },
  dialogAction: {
    display: "block",
  },
  dialogActionBtn: {
    padding: "6px 20px",
  },
  rightAlign: {
    float: "right",
  },
}));

const LoadPatientInfoDialog = ({
  loadPatientDialogOpen,
  handleLoadPatientDialogClose,
  handleSelectionFromLocalStorage,
  handleUploadedFile,
  fileLoadErrors,
  setFileLoadErrors,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [keysStored, setKeyStored] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  // file or local storage (ls)
  const [dataSource, setDataSource] = useState("");
  const [fileData, setFileData] = useState([]);
  const [notifyClearAllSuccess, setNotifyClearAllSuccess] = useState(false);

  // const { jsonToCSV } = usePapaParse();

  const handlePatientItemSelect = (value) => () => {
    const currentIndex = selectedKey.indexOf(value);
    const newChecked = [...selectedKey];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedKey(newChecked);
  };

  const handleClearAllNotificationOpen = () => {
    setNotifyClearAllSuccess(true);
  };

  const handleClearAllNotificationClose = () => {
    setNotifyClearAllSuccess(false);
  };

  const handleClearSavedData = (keysToClear) => {
    let keysStoredCopy = keysStored.slice(0);
    keysToClear.forEach((val) => {
      localStorage.removeItem(`mtx-local-data-${keysStored[val]}`);
      keysStoredCopy.splice(val, 1);
    });
    handleClearAllNotificationOpen();
    setKeyStored(keysStoredCopy);
  };

  // const handleDownloadAllSavedData = () => {
  //   const selectedKeysStored = selectedKey.map((key) => keysStored[key]);
  //   console.log("selected keys stored", selectedKeysStored);

  //   let jsonDataDump = [];
  //   let header = [];
  //   let rowData = [];
  //   if (localStorage.length) {
  //     for (let i = 0; i < localStorage.length; i++) {
  //       if (localStorage.key(i).startsWith("mtx-local-data-")) {
  //         const identifier = localStorage
  //           .key(i)
  //           .split("mtx-local-data-")[1]
  //           .trim();

  //         if (selectedKeysStored.includes(identifier)) {
  //           const localStorageData = JSON.parse(
  //             localStorage.getItem(localStorage.key(i))
  //           );
  //           //exclude parameters and dateSaved
  //           let { parameters, dateSaved, ...jsonData } = localStorageData;
  //           header = Object.keys(jsonData);
  //           rowData = Object.values(jsonData);

  //           //flat parametes and add them to the csv data
  //           // eslint-disable-next-line no-loop-func
  //           localStorageData["parameters"].forEach((paramObj) => {
  //             header.push(...Object.keys(paramObj));
  //             rowData.push(...Object.values(paramObj));
  //           });

  //           jsonDataDump.push(rowData);
  //         }
  //       }
  //     }
  //   }

  //   if (jsonDataDump.length > 0 && header.length) {
  //     const csvData = jsonToCSV([header, ...jsonDataDump]);
  //     exportCSVData("local-saved-data", csvData);
  //   }
  // };

  // const exportCSVData = (title, data) => {
  //   // Create link and download
  //   const link = document.createElement("a");
  //   const file = new Blob([data], { type: "text/plain" });
  //   link.href = URL.createObjectURL(file);
  //   link.download = `${title}.csv`;
  //   link.style.visibility = "hidden";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  useEffect(() => {
    if (selectedKey.length === 1) {
      setDataSource("ls");
    }
  }, [selectedKey]);

  const handleLoadLocalIdentifier = (ls_key) => () => {
    handleSelectionFromLocalStorage(keysStored[ls_key]);
  };

  const handleLoadFile = () => {
    handleUploadedFile(fileData);
    setDataSource("");
  };

  useEffect(() => {
    if (localStorage.length) {
      setKeyStored(localStorage.length);
      let keys = [];
      for (var i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).startsWith("mtx-local-data-")) {
          keys.push(localStorage.key(i).split("mtx-local-data-")[1]);
        }
      }
      setKeyStored(keys);
    }
  }, [loadPatientDialogOpen]);

  useEffect(() => {
    if (fileData && fileData.length) {
      setDataSource("file");
      setSelectedKey([]);
    }
  }, [fileData]);

  return (
    <>
      <Notify
        variant="success"
        message="Saved data cleared successfully"
        open={notifyClearAllSuccess}
        handleClose={handleClearAllNotificationClose}
      />
      <Dialog
        open={loadPatientDialogOpen}
        onClose={handleLoadPatientDialogClose}
        aria-labelledby="Load Patient Data"
        fullScreen={fullScreen}
        maxWidth={false}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="load-patient-data-dialog-title">
          Load Patient Data
        </DialogTitle>
        <DialogContent>
          <div className={classes.loadDialog}>
            <List
              subheader={
                <ListSubheader component="div" id="load-patient-data">
                  Patient Data saved locally
                </ListSubheader>
              }
            >
              {keysStored.length ? (
                keysStored.map((key, index) => {
                  return (
                    <ListItem
                      key={key}
                      button
                      onClick={handlePatientItemSelect(index)}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedKey.indexOf(index) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": "patient data local",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={key} />
                    </ListItem>
                  );
                })
              ) : (
                <Typography variant="body2">
                  No patient data found in the system
                </Typography>
              )}
            </List>
            <List
              subheader={
                <ListSubheader component="div" id="load-patient-data">
                  Import from file (.csv)
                </ListSubheader>
              }
            >
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                <ListItemText>
                  <LoadPatientRecordsFromCSV
                    infoText="Click to upload patient data"
                    setFileData={setFileData}
                    setFileLoadErrors={setFileLoadErrors}
                    setDataSource={setDataSource}
                  />
                </ListItemText>
              </ListItem>
            </List>
            <div>
              {fileLoadErrors && fileLoadErrors.length > 0 && (
                <>
                  {fileLoadErrors.map((err, index) => (
                    <FormHelperText key={index} error={true}>
                      {err}
                    </FormHelperText>
                  ))}
                </>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            color="primary"
            className={classes.dialogActionBtn}
            onClick={handleLoadPatientDialogClose}
          >
            Cancel
          </Button>
          <div className={classes.rightAlign}>
            {dataSource === "ls" && selectedKey.length >= 1 && (
              <Tooltip title="Delete selected patient data from local storage">
                <Button
                  className={classes.dialogActionBtn}
                  color="primary"
                  onClick={() => handleClearSavedData(selectedKey)}
                  disabled={!keysStored.length}
                >
                  Delete
                </Button>
              </Tooltip>
            )}
            {/* {dataSource === "ls" && selectedKey.length > 0 && (
            <>
              <Tooltip title="Download all locally saved data">
                <div>
                  <Button
                    color="primary"
                    onClick={handleDownloadAllSavedData}
                    disabled={!keysStored.length}                    
                  >
                    Download
                  </Button>
                </div>
              </Tooltip>
            </>
          )} */}
            {dataSource === "ls" && selectedKey.length === 1 && (
              <Button
                color="primary"
                onClick={handleLoadLocalIdentifier(selectedKey)}
                disabled={!keysStored.length}
                variant="contained"
              >
                Load Details
              </Button>
            )}
            {dataSource === "file" && (
              <Button color="primary" onClick={handleLoadFile}>
                Load File
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoadPatientInfoDialog;
