export function getChartOptions(
  patientData,
  patientConcData,
  creatData,
  simConcData,
  popHi,
  popMean,
  popRange,
  getCreatinineUnit
) {
  return {
    chart: {
      marginTop: 150,
      height: 800,
      // width: chartHeight * 1.5
    },
    exporting: {
      enabled: true,
      sourceHeight: 600,
      sourceWidth: 800,
      scale: 1,
      fallbackToExportServer: false,
    },
    loading: {
      hideDuration: 1000,
      showDuration: 1000,
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        tickInterval: 6,
        title: {
          text: "Hours since the start of MTX infusion",
          style: {
            fontSize: "16px",
          },
        },
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value} " + getCreatinineUnit(),
          style: {
            color: "black",
          },
        },
        title: {
          text: "Creatinine (" + getCreatinineUnit() + ")",
          style: {
            color: "black",
          },
        },
        opposite: true,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
      },
      {
        // Secondary yAxis
        type: "logarithmic",
        allowNegativeLog: true,
        // tickInterval: 1,
        min: 0.01,
        // max: 10000,
        gridLineWidth: 1,
        minorGridLineWidth: 1,
        // tickAmount: 25,
        // floor: 0.01,
        title: {
          text: "MTX (μM)",
          style: {
            color: "black",
          },
        },
        labels: {
          format: "{value} μM",
          style: {
            color: "black",
          },
        },
      },
    ],
    tooltip: {
      hideDelay: 1000,
      shared: true,
    },
    legend: {
      layout: "vertical",
      align: "center",
      x: 0,
      verticalAlign: "top",
      floating: true,
      shadow: true,
      borderWidth: 1,
      padding: 10,
      itemMarginTop: 4,
      itemStyle: {
        lineHeight: "14px",
      },
      title: {
        text: "*click on each legend to toggle the plot on/off",
        style: {
          fontWeight: 100,
        },
      },
    },
    series: [
      {
        name: "Creatinine",
        yAxis: 0,
        zIndex: 1,
        data: creatData,
        tooltip: {
          valueSuffix: getCreatinineUnit(),
          valueDecimals: 2,
        },
        color: "#930AA5",
      },
      {
        // fit - conc values from simulation
        name: "Patient's predicted elimination curve",
        yAxis: 1,
        zIndex: 1,
        data: simConcData,
        dashStyle: "ShortDash",
        tooltip: {
          valueDecimals: 2,
        },
        color: "black",
        marker: {
          enabled: false,
        },
      },
      {
        name: "Upper limit of population 95% probability",
        type: "spline",
        yAxis: 1,
        zIndex: 1,
        data: popHi,
        color: "#e7495e",
        tooltip: {
          valueDecimals: 2,
        },
        marker: {
          enabled: false,
        },
      },
      {
        name: "Population mean",
        type: "spline",
        yAxis: 1,
        zIndex: 1,
        data: popMean,
        color: "#5CA71C",
        tooltip: {
          valueDecimals: 2,
        },
        marker: {
          enabled: false,
        },
      },
      {
        name: "Population 95% probability",
        yAxis: 1,
        data: popRange,
        type: "arearange",
        linewidth: 0,
        linkedTo: ":previous",
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
        color: "#c1f7c5",
        tooltip: {
          valueDecimals: 2,
        },
      },
      {
        name: "Patient's Concentration values",
        yAxis: 1,
        data: patientConcData,
        lineWidth: 0,
        marker: {
          enabled: true,
          radius: 5,
        },
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
        tooltip: {
          valueDecimals: 2,
        },
        color: "black",
      },
      {
        name: "Glucarpidase guideline thresholds",
        yAxis: 1,
        data: [
          [36, 30],
          [42, 10],
          [48, 5],
          [24, patientData.loadin + patientData.mainin === 24 ? 120 : 50],
        ],
        lineWidth: 0,
        marker: {
          enabled: true,
          radius: 5,
        },
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
        tooltip: {
          valueDecimals: 2,
        },
        color: "blue",
      },
    ],
    plotOptions: {
      series: {
        connectNulls: true,
        stickyTracking: false,
        className: "popup-on-click",
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              floating: true,
              align: "left",
              width: 300,
              borderWidth: 1,
              x: 0,
            },
            yAxis: [
              {
                labels: {
                  align: "right",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                labels: {
                  align: "left",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                visible: false,
              },
            ],
          },
        },
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            legend: {
              floating: true,
              align: "left",
              width: 300,
              borderWidth: 1,
              x: 0,
            },
            yAxis: [
              {
                labels: {
                  align: "right",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                labels: {
                  align: "left",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                visible: false,
              },
            ],
          },
        },
      ],
    },
  };
}
