import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import { arialbd_font } from "./font";

import "jspdf-autotable";

export async function exportChartsToPdf(criticalWindowData, patientData) {
  const doc = new jsPDF("p", "pt", "letter", true);
  const elements = document.getElementsByClassName("mtx-report-chart");

  // use this custom font (Arialbd) to support unicode characters
  doc.addFileToVFS("Arialbd.ttf", arialbd_font);
  doc.addFont("Arialbd.ttf", "Arialbd", "bold");
  doc.setFont("Arialbd", "bold");

  //add header
  doc.text("MTX Simulation Report", 40, 50);

  //add the chart
  let posAfterChart = await addChart({ doc, elements });

  const pageHeight = doc.internal.pageSize.getHeight();

  if (posAfterChart > pageHeight) {
    doc.addPage();
    posAfterChart = 20;
  }

  // add elimination threshold
  doc.text("Elimination Threshold", 40, posAfterChart + 50);

  doc.text(
    criticalWindowData.upper.conc +
      " μM at " +
      criticalWindowData.upper.time.toFixed(1) +
      " h, " +
      criticalWindowData.lower.conc +
      " μM at " +
      criticalWindowData.lower.time.toFixed(1) +
      " h and AUC: " +
      criticalWindowData.auc.toFixed(1) +
      "μM*h",
    40,
    posAfterChart + 70
  );

  // add patient information
  doc.text("Patient Information", 40, posAfterChart + 100);

  let columnsq = [
    { title: "", dataKey: "name" },
    { title: "", dataKey: "val" },
  ];
  let rowsq = [
    { name: "Identifier", val: patientData.identifier },
    { name: "Age", val: patientData.age },
    { name: "Race", val: patientData.race },
    { name: "Ethnicity", val: patientData.ethnicity },
    {
      name: "Height",
      val: patientData.height + " " + (patientData.metricunits ? "cm" : "in"),
    },
    {
      name: "Weight",
      val: patientData.weight + " " + (patientData.metricunits ? "kg" : "lbs"),
    },
    { name: "BSA", val: patientData.surface + " m²" },
    { name: "Dose", val: patientData.dose + " gm/m²" },
    { name: "Load", val: patientData.load + " %" },
    { name: "Load Time", val: patientData.loadin + " h" },
    { name: "Main", val: patientData.main + " %" },
    { name: "Main Time", val: patientData.mainin + " h" },
  ];

  doc.autoTable(columnsq, rowsq, {
    willDrawCell: (data) => {
      if (data.section === "head") {
        return false;
      }
    },
    startY: posAfterChart + 110,
    margin: { left: 40 },
    styles: { overflow: "linebreak" },
    bodyStyles: { valign: "top" },
    tableWidth: 450,
    // headerStyles: { fillColor: [255,255,255]},
    columnStyles: {
      name: {
        cellWidth: 100,
      },
      val: {
        cellWidth: 200,
        fontStyle: "bold",
      },
    },
    theme: "striped",
  });

  // add measurements table
  var columns = [
    { title: "Time [h]", dataKey: "time" },
    { title: "Conc [μM]", dataKey: "conc" },
  ];

  // add Creat column with proper unit
  if (patientData.creatunitmml === true) {
    columns.push({ title: "Creat [μmol/L]", dataKey: "creat" });
  } else {
    columns.push({ title: "Creat [mg/dL]", dataKey: "creat" });
  }

  var rows = patientData.parameters.map(({ time, conc, creat }) => ({
    time: time.replace("T", " "),
    conc,
    creat,
  }));

  doc.text("Measurements", 40, doc.autoTable.previous.finalY + 30);

  doc.autoTable(columns, rows, {
    startY: doc.autoTable.previous.finalY + 40,
    margin: { left: 40 },
    styles: { overflow: "linebreak", cellWidth: 100 },
    headStyles: { font: "Arialbd", fontStyle: "bold" },
    bodyStyles: { valign: "top" },
    tableWidth: 500,
    theme: "striped",
  });

  //add disclaimer
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 10,
    theme: "plain",
    margin: {
      left: 40,
    },
    styles: { overflow: "linebreak", fontSize: 8 },
    body: [
      [
        {
          content:
            "Disclaimer: The User acknowledges and agrees that this tool will be used only as a reference aid, and that the information contained in the product is not intended to be (nor should it be used as) a substitute for the exercise of professional judgement.\n\nIn view of the possibility of human error or changes in medical science, the User should confirm the information in the product through independent sources.\n\nThis tool was developed by the Division of Clinical Pharmacology, Cincinnati Children’s Hospital Medical Center (CCHMC), Cincinnati, OH 45229 and Medimatics (Maastricht, Netherlands). All rights in the tool are reserved by Division of Clinical Pharmacology, Cincinnati Children’s Hospital Medical Center, Cincinnati, OH 45229. We do not make any warranty, express, or imply, or assume any liability for the use of this tool. Use of this website is taken as an agreement to these terms of usage. MTXPK.org is free for academic use.",
        },
      ],
    ],
  });

  doc.save(`mtx-report-${patientData.identifier}.pdf`);
}

async function addChart({ doc, elements }) {
  let top = 100;
  const padding = 10;

  // add chart elements
  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(i);
    const imgData = await htmlToImage.toPng(el);

    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;

    const pageWidth = doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - padding;
      elWidth = elWidth * ratio - padding;
    }

    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = 20;
    }

    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${i}`);
    top += elHeight;
  }

  return top;
}
