import React, { Fragment } from "react";
import { Grid, Typography, makeStyles, Box } from "@material-ui/core";
import chem from "../../images/chem.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  aboutIntroTextLeftPane: {
    width: "70%",
    paddingTop: 50,
    paddingBottom: 20,
    borderBottom: "2px solid #4d82cb",
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
      paddingTop: 20,
      paddingBottom: 10,
    },
  },
  aboutIntroTextRightPane: {
    width: "80%",
    margin: "0 auto",
    padding: 20,
    [theme.breakpoints.down("lg")]: {
      width: "95%",
      paddingTop: 10,
    },
  },

  aboutHighLightedTextWithBGLeftPane: {
    padding: 20,
    width: "70%",
    margin: "0 auto",
    "& img": {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("lg")]: {
      padding: 10,
      width: "90%",
    },
  },

  aboutHighLightedTextWithBGRightPane: {
    padding: 20,
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      padding: 10,
      width: "90%",
    },
  },

  aboutNoticeText: {
    width: "85%",
    margin: "0 auto",
    padding: 10,
    [theme.breakpoints.down("lg")]: {
      padding: 10,
      width: "90%",
    },
  },

  aboutContactUs: {
    width: "70%",
    margin: "0 auto",
    padding: 20,
  },

  aboutReference: {
    width: "86%",
    margin: "0 auto",
    padding: 20,
    [theme.breakpoints.down("lg")]: {
      padding: 10,
      width: "90%",
      marginBottom: 50,
    },
  },
  headerText: {
    color: "#4D82CB",
  },
  boldText: {
    fontWeight: 500,
  },
  introBiggerText: {
    fontSize: "x-large",
  },
  bgColor: {
    backgroundColor: "#f6f6f6",
    zIndex: -1,
  },
  rightBorder: {
    borderRight: "3px solid",
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },
  },
}));

const About = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.aboutIntroTextLeftPane}>
              <p
                className={clsx(
                  classes.headerText,
                  classes.boldText,
                  classes.introBiggerText
                )}
              >
                MTXPK.org is designed to help clinicians understand the
                pharmacokinetics of high-dose methotrexate, especially with
                regard to delayed clearance.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.bgColor}>
            <div className={classes.aboutIntroTextRightPane}>
              <Typography variant="h6" paragraph className={classes.headerText}>
                How it Works
              </Typography>
              <Typography variant="body1" paragraph>
                It uses a pharmacokinetic model for the dose of methotrexate to
                display the concentration vs time curve for an individual
                patient overlaid upon the population-predicted curve for that
                dose. The original model was based on >30,000 methotrexate
                concentrations in pediatric acute lymphoblastic leukemia
                patients treated on the Nordic Society of Pediatric Hematology &
                Oncology trials and required dose, infusion time, height, weight
                and serum creatinine. This model was validated in adults and
                children with a variety of doses, infusion times and ancestries.
                See Taylor et al. in References below.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ backgroundColor: "#21549B", color: "white" }}>
          <Grid item xs={12} sm={6} className={classes.rightBorder}>
            <Box>
              <div className={classes.aboutHighLightedTextWithBGLeftPane}>
                <Typography variant="h6" paragraph>
                  About
                </Typography>
                <Typography variant="body1" paragraph>
                  <span className={classes.boldText}>Methotrexate</span> is an
                  anti-folate chemotherapeutic that is used in high doses in
                  both hematologic and non-hematologic cancers. Methotrexate and
                  its metabolites are renally eliminated. High dose methotrexate
                  can be nephrotoxic, resulting in the inability to excrete the
                  drug, and increased risk of further toxicities, including
                  hepatotoxicity, myelotoxicity, mucositis, neurological
                  toxicity, and death in some cases. Patients receiving high
                  dose methotrexate require hydration, alkalization of the
                  urine, and leucovorin rescue to reduce the risk of toxicity.
                </Typography>
                <img src={chem} style={{ paddingTop: 20 }} alt="" />
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.aboutHighLightedTextWithBGRightPane}>
              <Typography variant="body1" paragraph>
                <span className={classes.boldText}>Glucarpidase</span> is an
                FDA-approved treatment indicated to reduce toxic plasma
                methotrexate concentrations in patients with delayed
                methotrexate clearance due to impaired renal function, which is
                further defined as plasma methotrexate concentrations greater
                than 2 standard deviations of the mean methotrexate excretion
                curve specific for the dose of methotrexate administered.
                Appropriate administration of glucarpidase is challenging due to
                the ambiguity in the labeled indication. A recent consensus
                guideline was published with an algorithm to provide clarity in
                when to administer glucarpidase, yet clinical interpretation of
                lab results that do not directly correspond to the algorithm
                prove to be a limitation of its use. Administration of
                glucarpidase should optimally occur within 48–60 hours from the
                start of the HDMTX infusion, because life-threatening toxicities
                may not be preventable beyond this time point.
              </Typography>
              <Typography variant="body1" paragraph>
                The prediction of which patients need glucarpidase remains a
                challenge, even with the guidelines defining the thresholds in
                methotrexate concentration at certain time points. The challenge
                comes when the sample was taken at an unusual time point, when
                the concentration is very close to the threshold, and when the
                creatinine is rapidly rising. We have received numerous
                questions from clinicians in these situations, who would like to
                be able to predict whether they need to order glucarpidase so
                that they can administer it within the optimal window of time
                for efficacy.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <div className={classes.aboutReference}>
            <Typography variant="h6" paragraph className={classes.headerText}>
              References
            </Typography>
            <Typography variant="body2" paragraph>
              MTXPK.org: A clinical decision support tool evaluating high‐dose
              methotrexate pharmacokinetics to inform post‐infusion care and use
              of glucarpidase. Taylor ZL, Mizuno T, Punt N, Baskaran B, Navarro
              Sainz A, Shuman W, Felicelli N, Vinks AA, Heldrup J, Ramsey LB.
              Clin Pharmacol Ther. 2020 Sep;108(3):635-643. doi:
              10.1002/cpt.1957. Epub 2020 Jul 18. PMID: 32558929.{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://ascpt.onlinelibrary.wiley.com/doi/abs/10.1002/cpt.1957"
              >
                https://ascpt.onlinelibrary.wiley.com/doi/abs/10.1002/cpt.1957
              </a>
            </Typography>
            <Typography variant="body2" paragraph>
              Preventing and Managing Toxicities of High-Dose Methotrexate.
              Howard SC, McCormick J, Pui CH, Buddington RK, Harvey RD.
              Oncologist. 2016 Dec;21(12):1471-1482.{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://theoncologist.alphamedpress.org/content/21/12/1471.long"
              >
                http://theoncologist.alphamedpress.org/content/21/12/1471.long
              </a>
            </Typography>
            <Typography variant="body2" paragraph>
              Voraxaze® (glucarpidase) [package insert]. Brentwood, TN 37027:
              BTG International Inc, 2019.{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://voraxaze.com/getmedia/21552a20-ab2d-4476-a5ac-93e920aecbed/VORAXAZE-PI_August-2019_2-column-format.pdf"
              >
                https://voraxaze.com/getmedia/21552a20-ab2d-4476-a5ac-93e920aecbed/VORAXAZE-PI_August-2019_2-column-format.pdf
              </a>
            </Typography>
            <Typography variant="body2" paragraph>
              Consensus Guideline for Use of Glucarpidase in Patients with
              High-Dose Methotrexate Induced Acute Kidney Injury and Delayed
              Methotrexate Clearance. Ramsey LB, Balis FM, O’Brien MM,
              Schmiegelow K, Pauley JL, Bleyer A, Widemann BC, Askenazi D,
              Bergeron S, Shirali A, Schwartz S, Vinks AA, Heldrup J.
              Oncologist. 2018 Jan;23(1):52-61.{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://theoncologist.alphamedpress.org/content/23/1/52.long"
              >
                http://theoncologist.alphamedpress.org/content/23/1/52.long
              </a>
            </Typography>
            <Typography variant="body2" paragraph>
              The Clinical Decision Support Tool for High-dose Methotrexate,
              MTXPK.org, is Capable of Accurately Fitting Individual Patient
              Data Across Diverse Pediatric & Adult Populations With Short &
              Long Infusions. Z. L. Taylor, A. M. Weisnicht, E. Poweleit, M. B.
              Bernhardt, T. P. Miller, S. M. Castellino, J. Barreto, S. Howard,
              L. B. Ramsey. ACCP Abstract Booklet poster number 071.{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://doi.org/10.1002/cpdd.1151"
              >
                https://doi.org/10.1002/cpdd.1151
              </a>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default About;
