import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  MobileStepper,
  Button,
} from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useTheme } from "@material-ui/styles";
import DosingAndMeasurements from "../../../images/dosingAndMeasurementsBothImage.png";
import SelectPatientIconFab from "../../../images/selectPatientIconFab.png";
import HighlightUploadPatientData from "../../../images/highlightUploadPatientData.png";
import PickAParticipantMobile from "../../../images/pickAParticipantMobile.png";
import PatientDataEntry from "../../../images/patientDataEntry.png";
import DosingEntry1 from "../../../images/dosingEntry1.png";
import DosingEntry2 from "../../../images/dosingEntry2.png";
import DosingEntry3 from "../../../images/dosingEntry32.png";
import Measurement1 from "../../../images/measurement1.png";
import Measurement2 from "../../../images/measurement2.png";
import MeasurementsDT from "../../../images/measurementsDTMobile.png";
import DownloadReportButton from "../../../images/downloadReportButton.png";
import ClearDataFromMachine from "../../../images/clearDataFromMachine.png";
import Plot from "../../../images/plotImageMobile.png";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    minHeight: 250,
    flexGrow: 1,
    margin: "0 auto",
  },
  headingAndImg: {
    textAlign: "center",
  },
  stepper: {
    marginBottom: 10,
  },
}));

const contentSteps = [
  {
    title: "Get Started",
    img: DosingAndMeasurements,
    text: [
      "Click on the Dosing and Measurements button (Simulation tab) to get started by entering patient data, dosing and measurements.",
    ],
  },
  {
    title: "Load Patient's Data",
    img: SelectPatientIconFab,
    text: [
      "Click on the select patient button to load a patient's data that was saved in the machine",
    ],
  },
  {
    title: "Load Patient's Data",
    img: PickAParticipantMobile,
    text: [
      "1. Select your patient from the list saved on the local device",
      "2. Click load",
    ],
  },
  {
    title: "Load Patient's Data",
    img: PatientDataEntry,
    text: [
      "3. Edit patient's demographic, dose or measurement in the patient data and measurement dialog",
    ],
  },
  {
    title: "Upload Patient's data",
    img: HighlightUploadPatientData,
    text: [
      "4. By using upload patient data dialog, a csv file with patient data can be uploaded",
    ],
  },
  {
    title: "Clear Patient Data from Device",
    img: ClearDataFromMachine,
    text: [
      "To clear patient data from the machine, click on the icon to select a patient (just like you would to load a saved patient). Select the box or boxes of patients whose data you want to remove and click the delete button",
    ],
  },
  {
    title: "Enter New Patient Demographics",
    img: PatientDataEntry,
    text: [
      "1. Enter an identifier (this will only be saved to your local machine, not captured by the website",
      "2. Enter age in years",
      "3. Select male or female",
      "4. Enter weight and height. The default is kilograms and centimeters but toggling the imperial unit button will allow entry in pounds and inches.",
    ],
  },
  {
    title: "Enter Dosing Information",
    img: DosingEntry1,
    text: [
      "1. Select an indication (leukemia, lymphoma or osteosarcoma)",
      "2. It will populate a typical dosing regimen but can be edited",
      "3. Edit dose (in g/m2)",
    ],
  },
  {
    title: "Enter Dosing Information",
    img: DosingEntry2,
    text: [
      "4. If there is a loading dose (as is common for leukemia regimens), specify the percentage of the dose and time of the loading dose (e.g., 10% in the first 0.5 hours)",
      "5. If there is no loading dose, 100% can be used for either the loading dose or the main dose.",
    ],
  },
  {
    title: "Enter Dosing Information",
    img: DosingEntry3,
    text: [
      "6. Specify the hours of infusion for each portion of the dose",
      "7. Specify how many hours you would like to see on the graph in the simulation box",
      "8. To calculate time to an elimination threshold, specify upper and lower thresholds",
      "9. Click next",
    ],
  },
  {
    title: "Methotrexate and Creatinine Concentrations",
    img: Measurement1,
    text: [
      "1. Enter time after the start of the infusion for each measurement",
      "2. Enter the baseline creatinine with a zero in the hours column, 0 in the Conc [uM] column, and the last creatinine measurement before the HDMTX infusion in the Creatininecolumn",
      "3. Enter methotrexate concentration in μM and creatinine concentration in mg/dL or toggle to enter values in μmol/A. A methotrexate concentration and creatinine concentration are required for each time measurement in each row. You can use tab to move between cells",
      "4. Add or delete rows as necessary",
    ],
  },
  {
    title: "Methotrexate and Creatinine Concentrations",
    img: MeasurementsDT,
    text: [
      "1. Type the date and time that the HDMTX infusion started in the first row, or select the calendar icon to select the date and time. If you type 14:00, it will change it to 2 PM. You can tab through the parts of the date and time without moving to the MTX concentration column.",
      "2. Continuing in the first row, enter 0 for the MTX concentration and enter the last creatinine measurement before the HDMTX infusion in the Creatinine column.",
      "3. Continue typing/selecting the time and date with the MTX and creatinine concentrations.",
      "4. Add rows as necessary",
    ],
  },
  {
    title: "Methotrexate and Creatinine Concentrations",
    img: Measurement2,
    text: [
      "5. Optional: Click save if you would like to be able to load this patient later. It saves to the local machine and can only be loaded from the same machine. The website saves no information",
      "6. Click Calculate",
    ],
  },
  {
    title: "Plot",
    img: Plot,
    text: [
      "1. The concentration versus time curves are drawn for the MTX(black line) and creatinine(purple line). The population mean and 95% probability for that dose and infusion time are drawn (green line, green shaded area). The glucarpidase guideline points (blue diamond) are drawn. Each of these can be toggled off by clicking on the item in the legend",
      "2. The elimination threshold shows when the patient is predicted to reach the thresholds of 0.2μM or 0.1μM",
      "3. Highlight a certain time point by typing in the box next to the Highlight button",
    ],
  },
  {
    title: "Plot",
    img: Plot,
    text: [
      "4. Click and drag the box to move it",
      "5. Click the black box to remove it",
      "6. The AUC for 0-432 hours is calculated",
      "7. Highlight a time by clicking on the graph",
      "8. Any boxes shown on the graph will be there when the report is downloaded",
    ],
  },
  {
    title: "Download Report",
    img: DownloadReportButton,
    text: ["To download a pdf report, click on the DOWNLOAD REPORT button."],
  },
];

const InstructionsMobileView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = contentSteps.length;

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <div className={classes.root}>
      <Paper square elevation={0} className={classes.headingAndImg}>
        <Typography>{contentSteps[activeStep].title}</Typography>
        <div style={{ minHeight: 200 }}>
          <img
            alt="screenshot"
            src={contentSteps[activeStep].img}
            style={{ width: "250px", padding: "10px 0" }}
          />
        </div>
      </Paper>

      {contentSteps[activeStep].text.map((t, index) => {
        return (
          <Typography key={index} variant="body2" className={classes.insText}>
            {t}
          </Typography>
        );
      })}

      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="progress"
        activeStep={activeStep}
        className={classes.stepper}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </div>
  );
};

export default InstructionsMobileView;
