import React from "react";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Header from "./Components/Header";
import Simulation from "./Pages/Simulation";
import Faq from "./Pages/FAQ";
import { BrowserRouter, Route } from "react-router-dom";

const Routes = () => (
  <BrowserRouter>
    <Header />
    <Route exact path="/" component={Home} />
    <Route path="/simulation" component={Simulation} />
    <Route path="/about" component={About} />
    <Route path="/faq" component={Faq} />
    <Route path="/contact" component={Contact} />
  </BrowserRouter>
);

export default Routes;
