import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {
  BottomNavHomeIcon,
  BottomNavSimulationIcon,
  BottomNavAboutIcon,
  BottomNavContactIcon,
  BottomNavFaqIcon,
} from "../Icons";
import { Link, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bottonNav: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 99,
    boxShadow: "0 -4px 9px -7px #333",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

const BottomNavBar = ({
  bottomNavState,
  handleBottomNavStateChange,
  location,
}) => {
  const classes = useStyles();
  const selectedColor = "#017aff";
  const defaultColor = "#8e8e93";
  const value = bottomNavState.pathMap.indexOf(location.pathname);

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleBottomNavStateChange}
        showLabels
        className={classes.bottonNav}
      >
        <BottomNavigationAction
          label="Home"
          icon={
            <BottomNavHomeIcon
              fillColor={value === 0 ? selectedColor : defaultColor}
            />
          }
          component={Link}
          to="/"
        />
        <BottomNavigationAction
          label="Simulation"
          icon={
            <BottomNavSimulationIcon
              fillColor={value === 1 ? selectedColor : defaultColor}
            />
          }
          component={Link}
          to="/simulation"
        />
        <BottomNavigationAction
          label="About"
          icon={
            <BottomNavAboutIcon
              fillColor={
                bottomNavState.value === 2 ? selectedColor : defaultColor
              }
            />
          }
          component={Link}
          to="/about"
        />
        <BottomNavigationAction
          label="FAQ"
          icon={
            <BottomNavFaqIcon
              fillColor={
                bottomNavState.value === 3 ? selectedColor : defaultColor
              }
            />
          }
          component={Link}
          to="/faq"
        />
        <BottomNavigationAction
          label="Contact"
          icon={
            <BottomNavContactIcon
              fillColor={
                bottomNavState.value === 4 ? selectedColor : defaultColor
              }
            />
          }
          component={Link}
          to="/contact"
        />
      </BottomNavigation>
    </>
  );
};

export default withRouter(BottomNavBar);
