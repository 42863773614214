import React from "react";
import {
  Paper,
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import InstructionsWebView from "./WebView";
import InstructionsMobileView from "./MobileView";
import MovieIcon from "@material-ui/icons/Movie";

const useStyles = makeStyles((theme) => ({
  howToPane: {
    padding: 20,
    width: "90%",
    margin: "0 auto",
  },
  headingText: {
    padding: 20,
  },
  headingTextSmall: {
    padding: 5,
    fontWeight: "bold",
  },
}));

const Instructions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Paper className={classes.howToPane}>
      {large && (
        <>
          <Button
            startIcon={<MovieIcon />}
            variant="outlined"
            color="primary"
            rel="noopener"
            target="_blank"
            href="https://fast.wistia.net/embed/iframe/hbdpu1ff5i?videoFoam=true"
          >
            Click here for a video tutorial
          </Button>
          <Typography className={classes.headingText}>
            Instructions on using this tool
          </Typography>
          <InstructionsWebView />
        </>
      )}
      {!large && (
        <>
          <Button
            startIcon={<MovieIcon />}
            variant="outlined"
            color="primary"
            rel="noopener"
            target="_blank"
            href="https://fast.wistia.net/embed/iframe/hbdpu1ff5i?videoFoam=true"
          >
            video tutorial
          </Button>
          <Typography className={classes.headingTextSmall} variant="body1">
            Instructions on using this tool
          </Typography>
          <InstructionsMobileView />
        </>
      )}
    </Paper>
  );
};

export default Instructions;
