import React from "react";
import Notification from "../SnackBar";

const Notify = ({ variant, message, open, handleClose }) => (
  <Notification
    variant={variant}
    message={message}
    notificationOpen={open}
    handleNotificationClose={handleClose}
  ></Notification>
);

export default Notify;
