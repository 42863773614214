import React, { useState } from "react";
import { Box, Button, makeStyles, withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import faqQn6NomographImg from "../../images/faqQn6NomographImg.png";
import faqQn91 from "../../images/faqQn91.png";
import faqQn8 from "../../images/faqQn8.png";
import faqQn92 from "../../images/faqQn92.png";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import android1a from "../../images/android-1a.png";
import android1 from "../../images/android-1.png";
import android2 from "../../images/android-2.png";
import android3 from "../../images/android-3.png";
import android4 from "../../images/android-4.png";
import ios1 from "../../images/ios-1.png";
import ios2 from "../../images/ios-2.png";
import ios3 from "../../images/ios-3.png";
import ios4 from "../../images/ios-4.png";
import clsx from "clsx";
import SampleFilePatientInfo from "../../assets/files/Lymphoma example_patientInfo.csv";
import SampleFileMeasurements from "../../assets/files/Lymphoma example_measurements.csv";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "50%",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  imgAlignCenterWithBreakpoint: {
    textAlign: "center",
    "& img": {
      margin: "0 auto",
      padding: 20,
      [theme.breakpoints.down("lg")]: {
        width: "80%",
      },
      [theme.breakpoints.down("md")]: {
        width: "80%",
      },
    },
  },
  imgAlignCenter: {
    width: "100%",
    textAlign: "center",
    "& img": {
      width: "80%",
      margin: "0 auto",
      padding: 20,
    },
  },
  mobileImages: {
    width: "100%",
    height: "auto",
  },
  tableAlignCenter: {
    margin: "0 auto",
  },
  leftPadded: {
    paddingLeft: 20,
  },
  faqBox: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    margin: "0 auto",
    paddingTop: 50,
    paddingBottom: 100,
  },
  rightButton: {
    alignSelf: "flex-end",
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function SimpleAccordion() {
  const classes = useStyles();
  const [expandAll, setExpandAll] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState([]);

  const handleChange = (panel) => (event, expanded) => {
    if (expanded) {
      setExpandedPanels([...expandedPanels, panel]);
    } else {
      const panels = expandedPanels.filter((p) => p !== panel);
      setExpandedPanels(panels);
    }
  };

  /**
   * just insert all the qn# to the expanded array to expand all
   * and
   * remove everything to hide all
   */
  const expandAllAction = () => {
    if (!expandAll) {
      setExpandAll(true);
      setExpandedPanels([
        "qn1",
        "qn2",
        "qn3",
        "qn4",
        "qn5",
        "qn6",
        "qn7",
        "qn8",
        "qn9",
        "qn10",
        "qn11",
        "qn12",
        "qn13",
        "qn14",
        "qn15",
        "qn16",
        "qn17",
        "qn18",
        "qn19",
      ]);
    } else {
      setExpandAll(false);
      setExpandedPanels([]);
    }
  };

  return (
    <Box component="div" className={classes.faqBox}>
      <Typography variant="h5" component="h5">
        FAQs
      </Typography>
      <Button
        className={classes.rightButton}
        size="medium"
        variant="contained"
        color="primary"
        onClick={() => expandAllAction()}
      >
        {expandAll ? "Hide All" : "Show All"}
      </Button>
      <Accordion
        expanded={expandedPanels.includes("qn1")}
        onChange={handleChange("qn1")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            1. If my patient’s plasma methotrexate levels are above the upper
            limit of population 95% probability, is glucarpidase indicated?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              Glucarpidase is indicated when the patient’s plasma methotrexate
              concentrations are greater than 2 standard deviations of the mean
              methotrexate excretion curve specific for the dose of methotrexate
              administered due to renal impairment. Two standard deviations
              above the mean is the same as being above the 95% probability
              upper limit, so yes Voraxaze would be indicated in this situation.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn2")}
        onChange={handleChange("qn2")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            2. What is the difference between the glucarpidase guideline cut off
            levels and the 2 standard deviations above the mean expected
            elimination curve?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              The glucarpidase guideline methotrexate cut off levels at 24, 36,
              42 and 48 hours are generalizable cut off thresholds which experts
              agree are known to put patients at risk for serious methotrexate
              toxicity given a variety of doses and infusion times. However,
              when individual patient data, including patient age, gender,
              height, weight, serum creatinine, dose, infusion time, and plasma
              methotrexate concentration are entered into MTXPK.org, your
              patient’s specific expected concentration vs time curve can be
              observed, allowing you to see if your patient’s elimination curve
              is 2 standard deviations above the mean expected curve at any time
              following the start of infusion (not just at standard timepoints).
              This provides better, more patient-specific information for
              clinical decision making.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn3")}
        onChange={handleChange("qn3")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            3. How is the elimination curve for my patient generated? What data
            is used?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              We have generated a model with &gt;31,000 plasma concentrations to
              understand how methotrexate is eliminated. The model parameters
              serve as reliable prior knowledge for a posteriori Bayesian
              estimation, allowing the tool to accurately forecast plasma MTX
              concentrations for a given patient using the information provided
              to it. In other words, the patient’s dose, infusion time, age,
              creatinine, and methotrexate measurements are used in the model
              equation to predict the line describing the future concentrations,
              based upon the understanding of how MTX is usually eliminated.
              When more MTX and creatinine concentration data is added, it
              improves the estimation through improved learning. See Taylor et
              al,&nbsp;
              <a href="https://doi.org/10.1002/cpt.1957">
                https://doi.org/10.1002/cpt.1957
              </a>
              &nbsp; for more details.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn4")}
        onChange={handleChange("qn4")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            4. Do I need to enter baseline values of serum creatinine for my
            patient and how do I do that?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              It’s not required but it helps the model predict the elimination
              better if it’s included. On the first line of the measurements
              page, you enter a baseline serum creatinine by entering 0 for the
              time, 0 for the plasma MTX concentration and then enter the
              patient’s baseline creatinine. Continue entering the patient’s
              plasma MTX levels by recording the time (from start of infusion)
              and corresponding plasma MTX and serum creatinine measurements.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn5")}
        onChange={handleChange("qn5")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            5. What if I don’t have a serum creatinine at the same time as the
            plasma MTX level?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              Enter the last available serum creatinine on that line, e.g., last
              value carried forward.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn6")}
        onChange={handleChange("qn6")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            6. Why don’t you have leucovorin dosing recommendations on the
            chart?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component={"div"} className={classes.leftPadded}>
            <Typography>
              Protocols for leucovorin dosing differ by infusion time and
              institution/collaborative group. <br />
              Here is a typical nomogram and cutoffs:
              <br />
              Adapted from Ramsey et al. <em>Oncologist.</em> 2018
              Jan;23(1):52-61 &amp; Bleyer <em>Cancer Treat Rev.</em> 1977; 4:
              87–101.
            </Typography>
            <Box
              component={"div"}
              className={classes.imgAlignCenterWithBreakpoint}
            >
              <img alt="" src={faqQn6NomographImg} />
            </Box>
            <Box component="div">
              <Table
                className={clsx(classes.table, classes.tableAlignCenter)}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Time after start of MTX infusion</TableCell>
                    <TableCell align="right">MTX conc (μM)</TableCell>
                    <TableCell align="right">Leucovorin dose</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      {"24h for infusions < 12h"}
                    </StyledTableCell>
                    <StyledTableCell align="left">{"≥10"}</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell rowSpan={4}>42h</StyledTableCell>
                    <StyledTableCell align="left">≥100</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        1000 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥20 and <100"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        100 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥2 and <20"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.1 and <2"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>

                  {/*  */}
                  <StyledTableRow>
                    <StyledTableCell rowSpan={4}>48h</StyledTableCell>
                    <StyledTableCell align="left">≥78</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        1000 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥12 and <78"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        100 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥1.6 and <12"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.1 and <1.6"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>

                  {/*  */}
                  <StyledTableRow>
                    <StyledTableCell rowSpan={4}>54h</StyledTableCell>
                    <StyledTableCell align="left">≥60</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        1000 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥12 and <60"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        100 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥1.6 and <12"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.1 and <1.6"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>

                  {/*  */}
                  <StyledTableRow>
                    <StyledTableCell rowSpan={4}>60h</StyledTableCell>
                    <StyledTableCell align="left">≥52</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        1000 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥6 and <52"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        100 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.63 and <6"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.1 and <0.63"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>

                  {/*  */}
                  <StyledTableRow>
                    <StyledTableCell rowSpan={4}>66h</StyledTableCell>
                    <StyledTableCell align="left">≥50</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        1000 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥5.2 and <50"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        100 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.54 and <5.2"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.1 and <0.54"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>

                  {/*  */}
                  <StyledTableRow>
                    <StyledTableCell rowSpan={4}>&gt;72h</StyledTableCell>
                    <StyledTableCell align="left">≥50</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        1000 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥5 and <50"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        100 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.5 and <5"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q3h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {"≥0.1 and <0.5"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        10 mg/m<sup>2</sup> Q6h
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
            <Typography>
              In addition, some protocols recommend the infusion time of
              leucovorin at doses &gt; 500 mg/m<sup>2</sup> should be &gt;1
              hour. To avoid excessive Ca++ of leucovorin at high doses,
              isovorin or sodium levofolinate can be substituted (they only
              contain the active L-form of folinic acid and thus less calcium).
              The dose of isovorin and sodium levofolinate should be 50% of the
              leucovorin dose (7.5 mg/m<sup>2</sup> instead of 15 mg/m
              <sup>2</sup>).
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn7")}
        onChange={handleChange("qn7")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            7. Can MTXPK.org be used in adult patients and will it accurately
            predict my adult patient PK curves?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              Yes. Because patient age and serum creatinine are used in the
              model, it will accurately predict expected and actual plasma MTX
              concentration vs time curves. When the pharmacokinetic model was
              tested with 1951 plasma MTX concentrations from 154 adult
              patients, the model fit the data with an R<sup>2</sup> of 0.96
              (compared to R<sup>2</sup> of 0.96 – 0.99 for pediatric data). See
              the supplement of Taylor et al,{" "}
              <a href="https://doi.org/10.1002/cpt.1957">
                https://doi.org/10.1002/cpt.1957
              </a>{" "}
              and search for Taylor on this website:{" "}
              <a href="https://accp1.onlinelibrary.wiley.com/doi/full/10.1002/cpdd.1151">
                https://accp1.onlinelibrary.wiley.com/doi/full/10.1002/cpdd.1151
              </a>
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn8")}
        onChange={handleChange("qn8")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            8. Can MTXPK.org be used after a patient receives glucarpidase?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              While we lack robust data from patients who have received
              glucarpidase, anecdotal use of the tool in such patients appears
              to show that it predicts post-glucarpidase elimination of MTX
              reliably when immunoassay MTX measurements are entered. The DAMPA
              created when MTX is cleaved by glucarpidase interferes with the
              immunoassay measurements for at least 48 hours after glucarpidase
              is administered. The patient with osteosarcoma in the graph below
              received glucarpidase at hour 26.
            </Typography>
            <Box component="div" className={classes.imgAlignCenter}>
              <img alt="" src={faqQn8} />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn9")}
        onChange={handleChange("qn9")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            9. Do you have example data I can enter to see the normal
            elimination? Or when glucarpidase is indicated?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              Yes. Choose Sample-data when loading a patient to load this
              example.
            </Typography>
            <Box component="div">
              <Typography className={classes.heading}>
                Pediatric ALL patient with normal elimination:
              </Typography>
              <Box component="div">
                <Table
                  className={clsx(classes.table, classes.tableAlignCenter)}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Age
                      </StyledTableCell>
                      <StyledTableCell align="left">9 years</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Gender
                      </StyledTableCell>
                      <StyledTableCell align="left">male</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Weight
                      </StyledTableCell>
                      <StyledTableCell align="left">30 kg</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Height
                      </StyledTableCell>
                      <StyledTableCell align="left">130 cm</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Indication
                      </StyledTableCell>
                      <StyledTableCell align="left">leukemia</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Dose
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <p>
                          5 g/m<sup>2</sup>
                        </p>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        10%
                      </StyledTableCell>
                      <StyledTableCell align="left">0.5 hours</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        90%
                      </StyledTableCell>
                      <StyledTableCell align="left">23.5 hours</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <Table
                  className={clsx(classes.table, classes.tableAlignCenter)}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>Measurements</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>hours</TableCell>
                      <TableCell align="right">MTX conc (μM)</TableCell>
                      <TableCell align="right">Creatinine (mg/dL)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        0
                      </StyledTableCell>
                      <StyledTableCell align="right">0</StyledTableCell>
                      <StyledTableCell align="right">0</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        24
                      </StyledTableCell>
                      <StyledTableCell align="right">95</StyledTableCell>
                      <StyledTableCell align="right">0.52</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        42
                      </StyledTableCell>
                      <StyledTableCell align="right">0.95</StyledTableCell>
                      <StyledTableCell align="right">0.32</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        48
                      </StyledTableCell>
                      <StyledTableCell align="right">0.43</StyledTableCell>
                      <StyledTableCell align="right">0.33</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        60
                      </StyledTableCell>
                      <StyledTableCell align="right">0.15</StyledTableCell>
                      <StyledTableCell align="right">0.33</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        72
                      </StyledTableCell>
                      <StyledTableCell align="right">0.12</StyledTableCell>
                      <StyledTableCell align="right">0.33</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        84
                      </StyledTableCell>
                      <StyledTableCell align="right">0.08</StyledTableCell>
                      <StyledTableCell align="right">0.34</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
              <Typography>should generate this curve:</Typography>
              <Box component="div" className={classes.imgAlignCenter}>
                <img alt="" src={faqQn91} />
              </Box>
            </Box>
            <Box component="div">
              <Typography className={classes.heading}>
                Adult lymphoma patient with delayed elimination: Click to
                download{" "}
                <a
                  href={SampleFilePatientInfo}
                  download="Lymphoma example_patientInfo.csv"
                >
                  Patient Info
                </a>{" "}
                and{" "}
                <a
                  href={SampleFileMeasurements}
                  download="Lymphoma example_measurements.csv"
                >
                  Measurements
                </a>{" "}
                for this sample patient.
              </Typography>
              <Box component="div">
                <Table
                  className={clsx(classes.table, classes.tableAlignCenter)}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Age
                      </StyledTableCell>
                      <StyledTableCell align="left">73 years</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Gender
                      </StyledTableCell>
                      <StyledTableCell align="left">male</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Weight
                      </StyledTableCell>
                      <StyledTableCell align="left">70 kg</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Height
                      </StyledTableCell>
                      <StyledTableCell align="left">168 cm</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Indication
                      </StyledTableCell>
                      <StyledTableCell align="left">lymphoma</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Dose
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <p>
                          3.5 g/m<sup>2</sup>
                        </p>{" "}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <Table
                  className={clsx(classes.table, classes.tableAlignCenter)}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>Measurements</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>hours</TableCell>
                      <TableCell align="right">MTX conc (μM)</TableCell>
                      <TableCell align="right">Creatinine (mg/dL)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        0
                      </StyledTableCell>
                      <StyledTableCell align="right">0</StyledTableCell>
                      <StyledTableCell align="right">1.55</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        22.8
                      </StyledTableCell>
                      <StyledTableCell align="right">23</StyledTableCell>
                      <StyledTableCell align="right">1.55</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        46.6
                      </StyledTableCell>
                      <StyledTableCell align="right">3.8</StyledTableCell>
                      <StyledTableCell align="right">1.74</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        71.3
                      </StyledTableCell>
                      <StyledTableCell align="right">1.3</StyledTableCell>
                      <StyledTableCell align="right">1.74</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        95.3
                      </StyledTableCell>
                      <StyledTableCell align="right">0.59</StyledTableCell>
                      <StyledTableCell align="right">1.66</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        119.4
                      </StyledTableCell>
                      <StyledTableCell align="right">0.41</StyledTableCell>
                      <StyledTableCell align="right">1.6</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        143.6
                      </StyledTableCell>
                      <StyledTableCell align="right">0.29</StyledTableCell>
                      <StyledTableCell align="right">1.67</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        167.2
                      </StyledTableCell>
                      <StyledTableCell align="right">0.22</StyledTableCell>
                      <StyledTableCell align="right">1.64</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        186
                      </StyledTableCell>
                      <StyledTableCell align="right">0.21</StyledTableCell>
                      <StyledTableCell align="right">1.57</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        209
                      </StyledTableCell>
                      <StyledTableCell align="right">0.2</StyledTableCell>
                      <StyledTableCell align="right">1.65</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        233.7
                      </StyledTableCell>
                      <StyledTableCell align="right">0.18</StyledTableCell>
                      <StyledTableCell align="right">1.45</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        257
                      </StyledTableCell>
                      <StyledTableCell align="right">0.16</StyledTableCell>
                      <StyledTableCell align="right">1.4</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        280
                      </StyledTableCell>
                      <StyledTableCell align="right">0.13</StyledTableCell>
                      <StyledTableCell align="right">1.22</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        305
                      </StyledTableCell>
                      <StyledTableCell align="right">0.12</StyledTableCell>
                      <StyledTableCell align="right">1.39</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
              <Typography>should generate this curve:</Typography>
              <Box component="div" className={classes.imgAlignCenter}>
                <img alt="" src={faqQn92} />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn10")}
        onChange={handleChange("qn10")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            10. How is the area under the curve (AUC) calculated?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              The AUC is calculated by the integral of the curve plotting the
              concentration over time, up to 400 hours (a reasonable
              approximation for infinity).
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn11")}
        onChange={handleChange("qn11")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            11. How is BSA calculated?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              We use the Mosteller equation (Mosteller, N Engl J Med. 1987 Oct
              22;317(17):1098.) because it more accurately fits body scan data
              in children than the DuBois equation from 1916. If you would like
              to have a toggle button for BSA equation to enable use of the
              DuBois equation, please contact us to let us know.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn12")}
        onChange={handleChange("qn12")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            12. Do you have an app I can use on my phone instead of going to the
            website?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              No, but you can get an icon on your home screen that looks like an
              app by following these steps.
            </Typography>
            <Accordion
              expanded={expandedPanels.includes("qn12ios")}
              onChange={handleChange("qn12ios")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>iOS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol>
                  <li>Launch Safari on your ‌iPhone‌ or ‌iPad‌. </li>
                  <li>Navigate to https://mtxpk.org/</li>
                  <li>
                    Tap the Share icon (the square with an arrow pointing out of
                    it) at the bottom of the screen.
                  </li>
                  <Box mt={3} mb={3}>
                    <Grid
                      container
                      xs={8}
                      justify="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <img
                          alt=""
                          src={ios1}
                          className={classes.mobileImages}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <li>
                    Scroll down to the list of actions and tap Add to Home
                    Screen. (If you don't see the action, scroll to the bottom
                    and tap Edit Actions, then tap Add next to the Add to Home
                    Screen action. After that, you'll be able to select it from
                    the Share Sheet.)
                  </li>
                  <Box mt={3} mb={3}>
                    <Grid
                      container
                      xs={8}
                      justify="center"
                      alignItems="center"
                      spacing={3}
                      m={10}
                    >
                      <Grid item>
                        <img
                          alt=""
                          src={ios2}
                          className={classes.mobileImages}
                        />
                      </Grid>
                      <Grid item>
                        <img
                          alt=""
                          src={ios3}
                          className={classes.mobileImages}
                        />
                      </Grid>
                      <Grid item>
                        <img
                          alt=""
                          src={ios4}
                          className={classes.mobileImages}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ol>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedPanels.includes("qn12android")}
              onChange={handleChange("qn12android")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Android</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol>
                  <li>Launch Chrome on your Android device</li>
                  <li>Navigate to https://mtxpk.org/</li>
                  <li>
                    You might be automatically prompted with a banner at the
                    bottom of the screen. Click Add MTXPK to Home Screen.
                  </li>
                  <Box mt={3} mb={3}>
                    <Grid
                      container
                      xs={8}
                      justify="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <img
                          alt=""
                          src={android1a}
                          className={classes.mobileImages}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <li>
                    If you were not prompted automatically then tap the menu
                    icon (3 dots in upper right-hand corner) and tap Install app
                    or Add to homescreen.
                  </li>
                  <Box mt={3} mb={3}>
                    <Grid
                      container
                      xs={8}
                      justify="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <img
                          alt=""
                          src={android1}
                          className={classes.mobileImages}
                        />
                      </Grid>
                      <Grid item>
                        <img
                          alt=""
                          src={android2}
                          className={classes.mobileImages}
                        />
                      </Grid>
                      <Grid item>
                        <img
                          alt=""
                          src={android3}
                          className={classes.mobileImages}
                        />
                      </Grid>
                      <Grid item>
                        <img
                          alt=""
                          src={android4}
                          className={classes.mobileImages}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ol>
              </AccordionDetails>
            </Accordion>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn13")}
        onChange={handleChange("qn13")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            13. Why does my curve not plot correctly?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              Check to ensure that you are entering the time since the START of
              the MTX infusion, the BSA is correct, the dose and infusion time
              is correct, and the units on the creatinine are correct. Please
              use the contact link in the top right of this page to contact us.
              You can download the patient information and the measurements to
              send with your email (but please don’t send any patient
              identifiable information).
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn14")}
        onChange={handleChange("qn14")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            14. Does the tool work for infants?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              It fits the data you enter very well but we are working on an
              analysis to demonstrate how well it works.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn15")}
        onChange={handleChange("qn15")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            15. Why is baseline albumin included?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              Pediatric and adult cohorts have demonstrated that the PK model
              fit improves with the inclusion of baseline albumin. The adult
              study is here:{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://bpspubs.onlinelibrary.wiley.com/doi/10.1111/bcp.15506"
              >
                https://bpspubs.onlinelibrary.wiley.com/doi/10.1111/bcp.15506
              </a>{" "}
              and the pediatric study will be added when published.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn16")}
        onChange={handleChange("qn16")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            16. Is the app able to be incorporated into an Electronic Health
            Record?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              We are working on programming an app in one Electronic Health
              Record System and will update when it is publicly available. It is
              currently incorporated in Resonance Health (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://resonancehealth.org/"
              >
                https://resonancehealth.org/
              </a>
              ) as a report.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn17")}
        onChange={handleChange("qn17")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            17. Can I target the next dose of HDMTX based on what I’ve plotted
            for a past dose?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              There is so much variability between courses that using the
              webtool to adjust doses is not recommended. It does a good job of
              predicting how long it will take to clear once you have at least
              one MTX concentration but not great at predicting one course to
              the next. You can try changing the baseline creatinine and dose to
              see what the normal elimination would look like, but since
              creatinine changes during the course and the webtool takes this
              into account, the predictions may not be very accurate for future
              courses.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn18")}
        onChange={handleChange("qn18")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            18. Can I use the AUC or curves plotted by the webtool in a
            publication?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              Yes, you are free to use the calculations provided by the website
              and the graphs in a publication, but please cite the manuscript
              describing the tool: Taylor et al. Clin Pharmacol Ther. 2020
              Sep;108(3):635-643. doi: 10.1002/cpt.1957 (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://ascpt.onlinelibrary.wiley.com/doi/10.1002/cpt.1957"
              >
                https://ascpt.onlinelibrary.wiley.com/doi/10.1002/cpt.1957
              </a>
              ). We will add the publication of the update that includes
              baseline albumin and an adjustment for infusion duration when it
              becomes available. Please use the Contact button in the top right
              corner of the page to ask any questions.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanels.includes("qn19")}
        onChange={handleChange("qn19")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            19. Why is the highlight time not on the appropriate point on the
            graph?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.leftPadded}>
            <Typography>
              You will need to re-enter any highlighted time point if the
              browser size is adjusted.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
