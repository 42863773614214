import React from "react";

const ParametersIconForMainNav = () => {
  return (
    <svg
      style={{ fill: "#fff" }}
      xmlns="http://www.w3.org/2000/svg"
      width="38.464"
      height="35.85"
      viewBox="0 0 38.464 35.85"
    >
      <path
        d="M33.718,267.517a4.514,4.514,0,0,0-3.846,2.174,1.6,1.6,0,0,1-1.461.765H23.577A1.526,1.526,0,0,0,22,271.925h0a1.526,1.526,0,0,0,1.577,1.469h4.834a1.6,1.6,0,0,1,1.371.765,4.562,4.562,0,0,0,5.946,1.7,4.26,4.26,0,0,0,1.824-1.7,1.6,1.6,0,0,1,1.371-.765H58.886a1.526,1.526,0,0,0,1.577-1.469h0a1.526,1.526,0,0,0-1.577-1.47H38.975a1.6,1.6,0,0,1-1.371-.765A4.517,4.517,0,0,0,33.718,267.517Zm0,5.878a1.539,1.539,0,0,1-1.652-1.4,1.514,1.514,0,0,1,1.5-1.539,1.539,1.539,0,0,1,1.652,1.4c0,.023,0,.047,0,.07a1.482,1.482,0,0,1-1.5,1.469ZM54.9,256.943a1.611,1.611,0,0,1-1.372-.765,4.563,4.563,0,0,0-5.946-1.7,4.258,4.258,0,0,0-1.824,1.7,1.6,1.6,0,0,1-1.372.765H23.577A1.526,1.526,0,0,0,22,258.413h0a1.526,1.526,0,0,0,1.577,1.469H44.386a1.6,1.6,0,0,1,1.372.765,4.562,4.562,0,0,0,5.946,1.7,4.258,4.258,0,0,0,1.824-1.7,1.611,1.611,0,0,1,1.372-.765h3.987a1.526,1.526,0,0,0,1.577-1.469h0a1.526,1.526,0,0,0-1.577-1.47Zm-5.257,2.939a1.483,1.483,0,0,1-1.5-1.468h0a1.5,1.5,0,0,1,3,0,1.482,1.482,0,0,1-1.5,1.469ZM46.8,281.029a4.517,4.517,0,0,0-3.847,2.175,1.61,1.61,0,0,1-1.371.765H23.629A1.535,1.535,0,0,0,22,285.389c0,.017,0,.033,0,.048h0a1.526,1.526,0,0,0,1.577,1.47H41.527a1.61,1.61,0,0,1,1.371.765,4.563,4.563,0,0,0,5.946,1.7,4.262,4.262,0,0,0,1.824-1.7,1.583,1.583,0,0,1,1.372-.765h6.833a1.528,1.528,0,0,0,1.59-1.457v-.013h0a1.526,1.526,0,0,0-1.577-1.469H52.053a1.583,1.583,0,0,1-1.372-.765A4.531,4.531,0,0,0,46.8,281.029Zm0,5.878a1.539,1.539,0,0,1-1.652-1.4,1.513,1.513,0,0,1,1.5-1.539,1.539,1.539,0,0,1,1.652,1.4c0,.023,0,.046,0,.07A1.494,1.494,0,0,1,46.8,286.907Z"
        transform="translate(-22 -254)"
      />
    </svg>
  );
};

const DosingIconForMainNav = () => {
  return (
    <svg
      style={{ fill: "#fff" }}
      xmlns="http://www.w3.org/2000/svg"
      width="33.429"
      height="33.429"
      viewBox="0 0 33.429 33.429"
    >
      <defs></defs>
      <g transform="translate(-20.059 -204.712)">
        <g transform="translate(20.309 204.962)">
          <g transform="translate(0)">
            <path
              d="M70.059,192.664l-7.4,7.4a.841.841,0,0,0,0,1.217.84.84,0,0,0,1.218,0l7.395-7.395a.861.861,0,0,0-1.217-1.218Z"
              transform="translate(-43.611 -173.611)"
            />
            <path
              d="M73.145,189.573a9.122,9.122,0,0,0-3.74-2.305l1.653-1.653a7.382,7.382,0,1,0-10.441-10.44l-15.442,15.4a7.382,7.382,0,1,0,10.44,10.441l1.653-1.653a9.82,9.82,0,0,0,2.305,3.741,9.513,9.513,0,0,0,6.786,2.827,9.647,9.647,0,0,0,6.785-2.784,9.392,9.392,0,0,0,2.784-6.785A9.651,9.651,0,0,0,73.145,189.573Zm-11.353-13.18a5.554,5.554,0,0,1,4-1.652,5.737,5.737,0,0,1,4,1.652,5.555,5.555,0,0,1,1.653,4,5.74,5.74,0,0,1-1.653,4l-2.436,2.436-1.74,1.739-2.914,2.915-6.134-6.177,6.046-6.046c2.61-2.61,4.829-.871,5.09-.7a.863.863,0,1,0,1.131-1.3c-1.218-1.088-4.393-2.306-7.438.739l-6.09,6.09-.609-.609ZM56.833,197.36,54.4,199.8a5.557,5.557,0,0,1-4,1.653,5.738,5.738,0,0,1-4-1.653,5.554,5.554,0,0,1-1.652-4,5.737,5.737,0,0,1,1.652-4l7.09-7.09,8,8-2.915,2.914Zm15.051,4.524a7.773,7.773,0,0,1-11.049,0,7.861,7.861,0,0,1-2.132-3.958l9.266-9.266a7.766,7.766,0,0,1,6.22,7.7A7.767,7.767,0,0,1,71.884,201.884Z"
              transform="translate(-43 -173)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const PatientDataIconForMainNav = () => {
  return (
    <svg
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      width="36.789"
      height="37.743"
      viewBox="0 0 36.789 37.743"
    >
      <g transform="translate(-13.391 -131.23)">
        <g transform="translate(0 116)">
          <g transform="translate(0)">
            <g transform="translate(13.391 15.23)">
              <path
                d="M47.575,169.516a10.942,10.942,0,1,0-15.949,4A18.822,18.822,0,0,0,19,189.693a1.505,1.505,0,0,0,1.388,1.615l.092,0h0a1.457,1.457,0,0,0,1.456-1.255,15.925,15.925,0,0,1,15.811-14.569A10.578,10.578,0,0,0,47.575,169.516Zm-9.75,2.974a7.943,7.943,0,1,1,7.943-7.943A7.943,7.943,0,0,1,37.825,172.489Z"
                transform="translate(-18.998 -153.569)"
              />
            </g>
          </g>
        </g>
        <g transform="translate(20.391 131.23)">
          <g transform="translate(0)">
            <path
              d="M20.21,169.516a10.942,10.942,0,1,1,15.949,4,18.822,18.822,0,0,1,12.623,16.175,1.505,1.505,0,0,1-1.388,1.615l-.092,0h0a1.457,1.457,0,0,1-1.456-1.255,15.925,15.925,0,0,0-15.811-14.569A10.579,10.579,0,0,1,20.21,169.516Zm9.75,2.974a7.943,7.943,0,1,0-7.943-7.943A7.943,7.943,0,0,0,29.96,172.489Z"
              transform="translate(-18.998 -153.569)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const BottomNavHomeIcon = ({ fillColor }) => {
  return (
    <svg
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      width="28.586"
      height="27.996"
      viewBox="0 0 28.586 27.996"
    >
      <g transform="translate(-2.289 -2.486)">
        <path
          d="M30.593,14.131,17.125,2.677a.8.8,0,0,0-1.041,0L2.572,14.181A.8.8,0,1,0,3.611,15.4l.106-.091V29.68a.8.8,0,0,0,.8.8H28.594a.8.8,0,0,0,.8-.8V15.22l.157.134a.8.8,0,0,0,1.039-1.223Zm-10.826,14.7H13.348V19.471h6.419Zm8.025.05H21.373V18.669a.8.8,0,0,0-.8-.8H12.545a.8.8,0,0,0-.8.8v10.21H5.324V13.946L16.6,4.341l11.187,9.514Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

const BottomNavSimulationIcon = ({ fillColor }) => {
  return (
    <svg
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      width="29.982"
      height="25.01"
      viewBox="0 0 29.982 25.01"
    >
      <g transform="translate(-4.71 -5.701)">
        <g transform="translate(4.71 5.701)">
          <g transform="translate(0 0)">
            <path
              d="M38.643,23.279a3.352,3.352,0,0,0,3.3-2.82,2.449,2.449,0,0,0,.037-.519,3.34,3.34,0,0,0-6.679,0,3.4,3.4,0,0,0,.705,2.041l-4.453,6.271a3.091,3.091,0,0,0-.816-.111,3.426,3.426,0,0,0-2.746,1.41l-3.6-1.187a3.347,3.347,0,1,0-6.085,2.226L16.082,35a5.028,5.028,0,0,0-.742-.074,3.357,3.357,0,1,0,2.635,1.3l2.226-4.3a3.659,3.659,0,0,0,.891.111,3.361,3.361,0,0,0,2.783-1.484L27.4,31.74a3.343,3.343,0,0,0,6.679-.223,3.256,3.256,0,0,0-.668-2L37.826,23.2A5.559,5.559,0,0,0,38.643,23.279Zm0-4.453a1.093,1.093,0,0,1,1.113,1.113v.186a1.121,1.121,0,0,1-1.113.928,1.047,1.047,0,0,1-.557-.148c-.074-.037-.148-.111-.223-.148a1.068,1.068,0,0,1-.334-.779A1.125,1.125,0,0,1,38.643,18.826ZM15.34,39.383a1.113,1.113,0,1,1,0-2.226.817.817,0,0,1,.3.037l.445.223h0a1.075,1.075,0,0,1,.371.853A1.093,1.093,0,0,1,15.34,39.383Zm5.752-9.574A1.141,1.141,0,0,1,19.978,28.7a1.113,1.113,0,0,1,2.226,0v.186A1.116,1.116,0,0,1,21.091,29.81Zm9.648,2.82a1.093,1.093,0,0,1-1.113-1.113.471.471,0,0,1,.037-.223,1.108,1.108,0,0,1,1.076-.891,1.121,1.121,0,0,1,.594.186,1.1,1.1,0,0,1,.52.928A1.116,1.116,0,0,1,30.739,32.63Z"
              transform="translate(-12 -16.6)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
const BottomNavAboutIcon = ({ fillColor }) => {
  return (
    <svg
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      width="25.01"
      height="25.01"
      viewBox="0 0 25.01 25.01"
    >
      <g transform="translate(-5 -4.8)">
        <path d="M17.5,6.745A10.555,10.555,0,0,1,28.065,17.3,10.536,10.536,0,0,1,17.811,27.837H6.945V17.194A10.568,10.568,0,0,1,17.5,6.745m0-1.945A12.5,12.5,0,0,0,5,17.194V29.81H17.838A12.507,12.507,0,0,0,17.5,4.8Z" />
        <g transform="translate(16.06 10.775)">
          <path
            d="M47.579,27.55a1.288,1.288,0,0,1-.417.945,1.389,1.389,0,0,1-.973.389,1.128,1.128,0,0,1-.528-.111,2.4,2.4,0,0,1-.445-.278,1.161,1.161,0,0,1-.306-.417,1.09,1.09,0,0,1-.111-.5.982.982,0,0,1,.111-.472,1.465,1.465,0,0,1,.306-.417,1.493,1.493,0,0,1,.445-.278,1.31,1.31,0,0,1,1.056,0,2.4,2.4,0,0,1,.445.278,1.352,1.352,0,0,1,.306.389A.913.913,0,0,1,47.579,27.55ZM47.44,37.86a1.192,1.192,0,0,1-1.223,1.278,1.134,1.134,0,0,1-.889-.361,1.284,1.284,0,0,1-.333-.917V31.5a1.354,1.354,0,0,1,.333-.945,1.236,1.236,0,0,1,.889-.361,1.134,1.134,0,0,1,.889.361,1.307,1.307,0,0,1,.333.945Z"
            transform="translate(-44.8 -26.3)"
          />
        </g>
      </g>
    </svg>
  );
};
const BottomNavContactIcon = ({ fillColor }) => {
  return (
    <svg
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      width="30.621"
      height="20.347"
      viewBox="0 0 30.621 20.347"
    >
      <path
        d="M204.679,561h27.264a1.69,1.69,0,0,1,1.679,1.679v16.99a1.582,1.582,0,0,1-1.679,1.679H204.679A1.56,1.56,0,0,1,203,579.668v-16.99A1.69,1.69,0,0,1,204.679,561Zm27.479,2.3-8.466,7.576,8.466,8.48Zm-9.557,8.552-3.975,3.559a.729.729,0,0,1-.99-.014l-3.745-3.386-7.82,7.878h24.566Zm-9.786-.818-8.351-7.576v15.985Zm-7.289-8.566,12.613,11.422,12.771-11.422Z"
        transform="translate(-203 -561)"
      />
    </svg>
  );
};

const BottomNavFaqIcon = ({ fillColor }) => {
  return (
    <svg
      fill="none"
      stroke={fillColor}
      strokeWidth="4"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 100 100"
    >
      <path
        d="M88.6,50c0-21.3-17.3-38.6-38.6-38.6S11.4,28.7,11.4,50S28.7,88.6,50,88.6S88.6,71.3,88.6,50z M43.7,42.3h-4.2
	c0-2.8,1.1-5.4,3.1-7.3c2-1.9,4.7-2.9,7.4-2.8c5.1,0.2,9.4,4.5,9.6,9.6c0.2,4.3-2.3,8.3-6.3,9.8c-1,0.4-1.6,1.4-1.6,2.6v1.4h-4.2
	v-1.4c0-2.9,1.7-5.5,4.3-6.5c2.3-0.9,3.8-3.2,3.7-5.7c-0.1-3-2.6-5.4-5.6-5.6c-1.6-0.1-3.2,0.5-4.3,1.6
	C44.4,39.2,43.7,40.7,43.7,42.3z M46.6,63.5c0-1.7,1.4-3,3-3s3,1.4,3,3s-1.4,3-3,3S46.6,65.1,46.6,63.5z"
        transform="translate(0 0)"
      />
    </svg>
  );
};

const PatientDataAndDosingIcon = ({ fillColor, width, height }) => {
  return (
    <svg
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "58"}
      height={height ? height : "53"}
      viewBox="0 0 58 53"
    >
      <g transform="translate(0)">
        <path
          d="M59.541,176.193a15.524,15.524,0,1,0-22.628,5.679A26.7,26.7,0,0,0,19,204.82a2.136,2.136,0,0,0,1.969,2.291l.131.006h0a2.066,2.066,0,0,0,2.065-1.781A22.593,22.593,0,0,1,45.6,184.667,15.008,15.008,0,0,0,59.541,176.193Zm-13.833,4.219a11.269,11.269,0,1,1,11.269-11.269A11.269,11.269,0,0,1,45.708,180.412Z"
          transform="translate(-18.998 -153.569)"
        />
        <g transform="translate(-18.998 -153.569)">
          <path d="M70.308,192.672l-7.636,7.636a.868.868,0,0,0,0,1.257.868.868,0,0,0,1.258,0l7.635-7.635a.889.889,0,0,0-1.257-1.258Z" />
          <path d="M74.125,190.112a9.419,9.419,0,0,0-3.862-2.38l1.707-1.707a7.622,7.622,0,1,0-10.78-10.779l-15.944,15.9a7.622,7.622,0,1,0,10.779,10.78l1.707-1.707a10.139,10.139,0,0,0,2.38,3.863A9.822,9.822,0,0,0,67.119,207a9.96,9.96,0,0,0,7.006-2.875A9.7,9.7,0,0,0,77,197.119,9.964,9.964,0,0,0,74.125,190.112ZM62.4,176.5a5.735,5.735,0,0,1,4.132-1.706,5.923,5.923,0,0,1,4.132,1.706,5.736,5.736,0,0,1,1.707,4.132,5.927,5.927,0,0,1-1.707,4.133l-2.515,2.515-1.8,1.8-3.009,3.01-6.333-6.378,6.243-6.243c2.695-2.7,4.986-.9,5.255-.719a.891.891,0,1,0,1.168-1.347c-1.258-1.123-4.536-2.381-7.68.763l-6.288,6.288-.629-.629Zm-5.12,21.649-2.515,2.515a5.738,5.738,0,0,1-4.133,1.707,5.924,5.924,0,0,1-4.132-1.707,5.735,5.735,0,0,1-1.706-4.132A5.923,5.923,0,0,1,46.5,192.4l7.321-7.321,8.265,8.264-3.01,3.009Zm15.54,4.671a8.025,8.025,0,0,1-11.408,0,8.116,8.116,0,0,1-2.2-4.087l9.567-9.567a8.018,8.018,0,0,1,6.422,7.95A8.02,8.02,0,0,1,72.823,202.823Z" />
        </g>
      </g>
    </svg>
  );
};
const IntervalIcon = ({ fillColor }) => {
  return (
    <svg
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="53"
      viewBox="0 0 58 53"
    >
      <path
        d="M38.449,274.361a6.263,6.263,0,0,0-5.4,3.275A2.213,2.213,0,0,1,31,278.788H24.214A2.214,2.214,0,0,0,22,281h0a2.213,2.213,0,0,0,2.214,2.213H31a2.212,2.212,0,0,1,1.925,1.152,6.174,6.174,0,0,0,10.907,0,2.212,2.212,0,0,1,1.925-1.152H73.778A2.213,2.213,0,0,0,75.992,281h0a2.214,2.214,0,0,0-2.214-2.214H45.828a2.212,2.212,0,0,1-1.925-1.152A6.263,6.263,0,0,0,38.449,274.361Zm0,8.854a2.214,2.214,0,1,1,2.1-2.319c0,.035,0,.07,0,.106a2.159,2.159,0,0,1-2.1,2.213Zm29.732-24.782a2.233,2.233,0,0,1-1.926-1.152,6.173,6.173,0,0,0-10.906,0,2.213,2.213,0,0,1-1.926,1.152H24.214A2.214,2.214,0,0,0,22,260.647h0a2.213,2.213,0,0,0,2.214,2.213H53.423a2.213,2.213,0,0,1,1.926,1.152,6.173,6.173,0,0,0,10.906,0,2.233,2.233,0,0,1,1.926-1.152h5.6a2.213,2.213,0,0,0,2.214-2.213h0a2.214,2.214,0,0,0-2.214-2.214ZM60.8,262.86a2.16,2.16,0,0,1-2.106-2.212h0a2.106,2.106,0,1,1,4.212,0,2.159,2.159,0,0,1-2.1,2.213Zm-3.995,31.855a6.266,6.266,0,0,0-5.4,3.276,2.232,2.232,0,0,1-1.925,1.152h-25.2A2.214,2.214,0,0,0,22,301.283c0,.025,0,.049,0,.073h0a2.214,2.214,0,0,0,2.214,2.214h25.2a2.232,2.232,0,0,1,1.925,1.152,6.174,6.174,0,0,0,10.907,0,2.194,2.194,0,0,1,1.926-1.152H73.76a2.214,2.214,0,0,0,2.232-2.2v-.019h0a2.213,2.213,0,0,0-2.214-2.213H64.186a2.194,2.194,0,0,1-1.926-1.152A6.282,6.282,0,0,0,56.807,294.715Zm0,8.855a2.214,2.214,0,1,1,2.1-2.319c0,.035,0,.07,0,.1A2.177,2.177,0,0,1,56.807,303.57Z"
        transform="translate(-22 -254)"
      />
    </svg>
  );
};
const SettingsGearIcon = ({ fillColor }) => {
  return (
    <svg
      fill="none"
      stroke={fillColor}
      strokeWidth="4"
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
    >
      <g transform="translate(-436 -233)">
        <g transform="translate(364.471 223)">
          <path
            d="M78.512,57.591l4.915-4.915-.792-1.34a21.909,21.909,0,0,1-2.351-5.682l-.39-1.507H73.686c-.094-.956-.157-1.915-.157-2.879a29.105,29.105,0,0,1,.484-5.158H79.9l.388-1.507a21.979,21.979,0,0,1,2.351-5.682l.792-1.34-3.94-3.938a29.441,29.441,0,0,1,5.682-5.682l3.938,3.94,1.34-.792a21.909,21.909,0,0,1,5.682-2.351l1.511-.394V12.484A29.1,29.1,0,0,1,102.8,12h2.879v6.365l1.507.388a21.979,21.979,0,0,1,5.682,2.351l1.34.792,4.5-4.5,5.684,5.684-4.5,4.5.792,1.34a21.908,21.908,0,0,1,2.351,5.682l.39,1.509h6.365v8.037h-6.365l-.388,1.507a21.978,21.978,0,0,1-2.351,5.682l-.792,1.34,4.5,4.5-5.684,5.684-4.5-4.5-1.34.792a21.908,21.908,0,0,1-5.682,2.351l-1.509.39v6.365H97.639V61.893L96.133,61.5a21.979,21.979,0,0,1-5.682-2.351l-1.34-.792-5.563,5.563-3.661-4.444C79.4,58.869,78.944,58.238,78.512,57.591Z"
            transform="translate(0 0)"
          />
          <circle cx="12" cy="12" r="12" transform="translate(89.529 28)" />
        </g>
      </g>
    </svg>
  );
};

export {
  ParametersIconForMainNav,
  DosingIconForMainNav,
  PatientDataIconForMainNav,
  BottomNavAboutIcon,
  BottomNavContactIcon,
  BottomNavHomeIcon,
  BottomNavSimulationIcon,
  PatientDataAndDosingIcon,
  IntervalIcon,
  SettingsGearIcon,
  BottomNavFaqIcon,
};
