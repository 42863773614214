import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Tabs,
  Box,
  Tab,
  Button,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { PatientDataAndDosingIcon } from "../../../Components/Icons";
import selectPatientFab from "../../../images/selectPatientIconFab.png";
import pickAParticipant from "../../../images/pickAParticipant.png";
import LoadedFile from "../../../images/loadedUploadedFile.png";
import HighlightUploadPatientData from "../../../images/highlightUploadPatientData.png";
import PatientDataEntry from "../../../images/patientDataEntry.png";
import SamplePatientDataEntry from "../../../images/samplePatientDataEntry.png";
import DosingEntry from "../../../images/dosingEntryNew.png";
import MeasurementInHours from "../../../images/measurementsInhours.png";
import MeasurementInDateTime from "../../../images/measurementsInDateAndTime.png";
import MeasCSVFormat from "../../../images/measCSVFormat.png";
import MeasurementsUpload from "../../../images/measurementsUpload.png";
import MeasurementsParse from "../../../images/measurementsParse.png";
import DownloadReportButton from "../../../images/downloadReportButton.png";
import PlotBurger from "../../../images/plotBurger.png";
import ClearDataFromMachine from "../../../images/clearDataFromMachine.png";
import GraphDrag from "../../../images/graphDrag.gif";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabRoot: {
    maxWidth: "none",
  },
  root: {
    flexGrow: 2,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  wrapper: {
    width: "100%",
    display: "inline-flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: "260px",
  },
  getStartedPane: {
    width: "100%",
  },
  patientDosingIconPane: {
    backgroundColor: theme.palette.primary.main,
    padding: "15px",
    borderRadius: "5px",
    display: "inline-block",
  },
  instuctionsPane: {
    width: "850px",
  },
  instuctionsPaneCenterAlign: {
    width: "850px",
    margin: "0 auto",
  },
  instructionContentPane: {
    textAlign: "center",
    minHeight: 350,
  },
  nextButton: {
    float: "right",
  },
  instGridItem: {
    padding: 10,
  },
  instGridItemTopAndBottomPadding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  instructionText: {
    textAlign: "left",
    padding: "20px 10px",
  },
  instructionTextCenterAligned: {
    textAlign: "center",
    padding: "20px 10px",
  },
  instructionTextNoPadding: {
    textAlign: "left",
  },
  whiteFont: {
    color: "#fff",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tab-content-info-${index}`}
      aria-labelledby={`tab-content-info-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Instructions = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNextTabButtonClick = (event) => {
    setValue(value + 1);
  };

  const handleFinishButtonClick = (event) => {
    setValue(0);
  };

  const handlePrevTabButtonClick = (event) => {
    setValue(value - 1);
  };

  function getTabContents(index) {
    return {
      id: `tab-content-info-${index}`,
      "aria-controls": `tab-content-info-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="instructions-how-to"
        className={classes.tabs}
      >
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label="Get Started"
          {...getTabContents(0)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label="Option 1: Load a saved patient's data"
          {...getTabContents(1)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label="Option 2: Upload patient data"
          {...getTabContents(2)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label="Clear saved patient data"
          {...getTabContents(3)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label="Entering a new patient’s demographic data"
          {...getTabContents(4)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label="Enter dosing information"
          {...getTabContents(5)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label={
            <>
              <Typography variant="title">Methotrexate & Creatinine</Typography>
              <Typography variant="caption">
                Entering concentration values in hours
              </Typography>
            </>
          }
          {...getTabContents(6)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label={
            <>
              <Typography variant="title">Methotrexate & Creatinine</Typography>
              <Typography variant="caption">
                Entering concentration values as date and time
              </Typography>
            </>
          }
          {...getTabContents(7)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label={
            <>
              <Typography variant="title">Methotrexate & Creatinine</Typography>
              <Typography variant="caption">Entering values</Typography>
            </>
          }
          {...getTabContents(8)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label={
            <>
              <Typography variant="title">Methotrexate & Creatinine</Typography>
              <Typography variant="caption">Uploading file</Typography>
            </>
          }
          {...getTabContents(9)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label="Plot"
          {...getTabContents(10)}
        />
        <Tab
          classes={{
            wrapper: classes.wrapper,
            root: classes.tabRoot,
          }}
          label="Download Report or Graph"
          {...getTabContents(11)}
        />
      </Tabs>
      {/* DONE */}
      <TabPanel value={value} index={0}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography
              variant="h6"
              className={classes.instructionTextCenterAligned}
            >
              Get Started
            </Typography>
            <div className={classes.patientDosingIconPane}>
              <PatientDataAndDosingIcon fillColor={"#fff"} />
              <Typography className={classes.whiteFont}>START</Typography>
            </div>
            <Typography
              variant="body2"
              className={classes.instructionTextCenterAligned}
            >
              Click on the icon on the left
            </Typography>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE */}
      <TabPanel value={value} index={1}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">Load patient's data</Typography>
            <Grid container>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={selectPatientFab}
                  style={{ width: "200px" }}
                />
                <Typography variant="body2" className={classes.instructionText}>
                  1. Click select patient
                </Typography>
              </Grid>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={pickAParticipant}
                  style={{ width: "200px" }}
                />
                <br />
                <Typography className={classes.instructionText} variant="body2">
                  2. Select your patient from the list saved on the local
                  machine <br />
                  3. Click load details and edit if necessary <br />
                  4. Click Next <br />
                  5. Be careful not to click two boxes – the Load Details button
                  will disappear and only the Delete and Cancel buttons will be
                  displayed
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE */}
      <TabPanel value={value} index={2}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">Upload patient data</Typography>
            <Grid container>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={SamplePatientDataEntry}
                  style={{ width: "200px" }}
                />
                <Typography variant="body2" className={classes.instructionText}>
                  1. Load the Sample-data patient as described in Option 1 by
                  clicking on the blue circle icon
                  <br />
                  2. Download the Sample-data_patientInfo.csv file by clicking
                  the Download Patient Info button
                  <br />
                  3. Open the file on your computer and replace the sample
                  patient’s information with your patient’s information and save
                  as a new file name
                  <br />
                  4. Click select patient (same blue circle icon as option 1)
                </Typography>
              </Grid>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={HighlightUploadPatientData}
                  style={{ width: "200px" }}
                />
                <Typography variant="body2" className={classes.instructionText}>
                  5. Hit “Click to upload patient data”
                </Typography>
              </Grid>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={LoadedFile}
                  style={{ width: "200px" }}
                />
                <Typography className={classes.instructionText} variant="body2">
                  6. Select the file on your computer to upload
                  <br />
                  7. Click Load File
                  <br />
                  8. Edit patient’s demographic, dose, or measurement data
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* Clear saved patient data - Done */}
      <TabPanel value={value} index={3}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">Clear saved patient data</Typography>
            <img alt="screenshot" src={ClearDataFromMachine} />
            <Typography
              className={classes.instructionTextCenterAligned}
              variant="body2"
            >
              To clear patient data from the machine, click on the icon to
              select a patient (just like you would to load a saved patient).
              Select the box or boxes of patients whose data you want to remove
              and click the delete button.
            </Typography>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE */}
      <TabPanel value={value} index={4}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">
              New patient's demographics data
            </Typography>
            <Grid container>
              <Grid item sm>
                <img
                  alt="screenshot"
                  src={PatientDataEntry}
                  style={{ width: "350px" }}
                />
              </Grid>
              <Grid item sm>
                <Typography className={classes.instructionText} variant="body2">
                  1. Enter an identifier (this will only be saved to your local
                  machine, not captured by the website)
                  <br />
                  2. Enter age in years
                  <br />
                  3. Select male or female
                  <br />
                  4. Select race and ethnicity
                  <br />
                  5. Enter weight and height. The default is kilograms and
                  centimeters but toggling the imperial unit button will allow
                  entry in pounds and inches
                  <br />
                  6. Select whether the patient has a baseline albumin result
                  &lt; 2.5 g/dL, whether the patient has Down Syndrome, or a
                  pleural effusion (Yes/No for each)
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE */}
      <TabPanel value={value} index={5}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">Enter dosing information</Typography>
            <Grid container>
              <Grid item sm>
                <img
                  alt="screenshot"
                  src={DosingEntry}
                  style={{ width: "350px" }}
                />
              </Grid>
              <Grid item sm>
                <Typography className={classes.instructionText} variant="body2">
                  1. Select an indication (leukemia, lymphoma or osteosarcoma)
                  <br />
                  2. It will populate a typical dosing regimen but can be edited
                  <br />
                  3. Edit dose (in g/m<sup>2</sup>)
                  <br />
                  4. If there is a loading dose (as is common for leukemia
                  regimens), specify the percentage of the dose and time of the
                  loading dose (e.g., 10% in first 0.5 hours)
                  <br />
                  5. If there is no loading dose, 100% can be used for either
                  the loading dose or the main dose
                  <br />
                  6. Specify the hours of infusion for each portion of the dose
                  <br />
                  7. Specify how many hours you would like to see on the graph
                  in the simulation box or leave black to fit the graph
                  <br />
                  8. Specify the elimination threshold in hours you would like
                  to have calculated. If left blank, it will default to 0.2 uM
                  and 0.1 uM
                  <br />
                  9. Click Next button or (2) Measurements at the top of the
                  window
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE FIX PADDING */}
      <TabPanel value={value} index={6}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">Methotrexate & Creatinine</Typography>
            <Typography variant="h6">Concentration values in hours</Typography>
            <Grid container>
              <Grid
                item
                container
                className={classes.instGridItemTopAndBottomPadding}
              >
                <Grid item sm>
                  <img
                    alt="screenshot"
                    src={MeasurementInHours}
                    style={{ width: "450px" }}
                  />
                </Grid>
                <Grid item sm>
                  <Typography
                    className={classes.instructionTextNoPadding}
                    variant="body2"
                  >
                    1. Enter methotrexate concentrations and creatinine
                    concentrations in hours after the start of infusion. Use the
                    toggle under the time column to switch to entering as date
                    and time (instructions for doing this are in the next
                    section of the instructions)
                    <br />
                    2. Enter the baseline creatinine with a zero in the hours
                    column, 0 in the Conc [uM] column, and the last creatinine
                    measurement before the HDMTX infusion in the Creatinine
                    column.
                    <br />
                    3. Enter time after the start of the infusion for each MTX
                    concentration measurement
                    <br />
                    4. Enter methotrexate concentration in uM and creatinine
                    concentration in mg/dL or hit the toggle to enter in umol/L.
                    If they were drawn at the same time, the methotrexate
                    concentration and creatinine concentration can go in the
                    same row. You can hit tab to move between cells.
                    <br />
                    5. Add rows as necessary
                    <br />
                    6. Optional: Click save if you would like to be able to load
                    this patient later. It saves to the local machine and can
                    only be loaded from the same machine. The website saves no
                    information.
                    <br />
                    7. To return to Patient Data and Dosing screen, click (1)
                    Patient Data and Dosing at the top of the window
                    <br />
                    8. To delete rows, hit the trash can on the right
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE FIX PADDING */}
      <TabPanel value={value} index={7}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">Methotrexate & Creatinine</Typography>
            <Typography variant="h6">
              Concentration values as date and time
            </Typography>
            <Grid container>
              <Grid
                item
                container
                className={classes.instGridItemTopAndBottomPadding}
              >
                <Grid item sm>
                  <img
                    alt="screenshot"
                    src={MeasurementInDateTime}
                    style={{ width: "450px" }}
                  />
                </Grid>
                <Grid item sm>
                  <Typography
                    className={classes.instructionTextNoPadding}
                    variant="body2"
                  >
                    1. Type the date and time that the HDMTX infusion started in
                    the first row, or select the calendar icon to select the
                    date and time. If you type 14:00, it will change it to 2 PM.
                    You can tab through the parts of the date and time without
                    moving to the MTX concentration column.
                    <br />
                    2. Continuing in the first row, enter 0 for the MTX
                    concentration and enter the last creatinine measurement
                    before the HDMTX infusion in the Creatinine column.
                    <br />
                    3. Continue typing/selecting the time and date with the MTX
                    and creatinine concentrations.
                    <br />
                    4. Add rows as necessary.
                    <br />
                    5. Optional: Click save if you would like to be able to load
                    this patient later. It saves to the local machine and can
                    only be loaded from the same machine. The website saves no
                    information.
                    <br />
                    6. To return to Patient Data and Dosing screen, click (1)
                    Patient Data and Dosing at the top of the window
                    <br />
                    7. To delete rows, hit the trash can on the right
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE FIX text bold if necessary, include data */}
      <TabPanel value={value} index={8}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">
              Entering the concentration values
            </Typography>
            <Grid container>
              <Grid
                item
                container
                className={classes.instGridItemTopAndBottomPadding}
              >
                <Grid item sm>
                  <Typography
                    className={classes.instructionTextNoPadding}
                    variant="body2"
                  >
                    1. Click Import Measurements button
                    <br />
                    <br />
                    2. The date/time or hours can be used and should be the
                    first number entered, followed by a space, then the MTX
                    concentration, a space, and the creatinine concentration.
                    Hitting the Enter or Return key will start a new row.
                    <br />
                    <br />
                    3. Accepted date and time formats: 
                    <br />
                    <b>MM/DD/YYYY HH:MM</b> or <b>MM/DD/YYYY,HH:MM</b>
                    <br />
                    E.g., <br />
                    03/10/2022 13:24 95 0.52 (for measurements with date and
                    time),
                    <br />
                    24 0.95 0.32 (for measurements with hour since the start of
                    infusion)
                    <br />
                    <br />
                    4. The first row of data should be the start time of the
                    methotrexate infusion or zero hours, with a zero for the MTX
                    concentration and the baseline creatinine (last creatinine
                    measurement prior to the start of the MTX infusion)
                    <br />
                    <br />
                    5. Click Parse button
                    <br />
                    <br />
                    6. To return to Patient Data and Dosing screen, click (1)
                    Patient Data and Dosing at the top of the window
                    <br />
                    <br />
                    7. To delete rows, hit the trash can on the right
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE */}
      <TabPanel value={value} index={9}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">
              Uploading the concentration values
            </Typography>
            <Grid container>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={MeasCSVFormat}
                  style={{ width: "200px" }}
                />
                <Typography className={classes.instructionText} variant="body2">
                  1. Organize your data into a spreadsheet and save as a CSV
                  file. The first column should be the date/time or hours after
                  the start of the infusion, second column should be MTX
                  concentration, and third column creatinine concentration.
                  <br />
                  <br />
                  A header row is required but the labels don’t matter. If a
                  header row is not present it will skip the first row of data
                  <br />
                </Typography>
              </Grid>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={MeasurementsUpload}
                  style={{ width: "200px" }}
                />
                <Typography className={classes.instructionText} variant="body2">
                  2. Click on the text that says “Click to upload measurements
                  data”
                </Typography>
              </Grid>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={MeasurementsParse}
                  style={{ width: "200px" }}
                />
                <Typography className={classes.instructionText} variant="body2">
                  3. Click on blue parse file button
                  <br />
                  4. Ensure that the toggles are correct for the hours or
                  date/time and creatinine units
                  <br />
                  5. Click Calculate button.
                  <br />
                  6. To return to Patient Data and Dosing screen, click (1)
                  Patient Data and Dosing at the top of the window
                  <br />
                  7. To delete rows, hit the trash can on the right
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE */}
      <TabPanel value={value} index={10}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">Plot</Typography>
            <Grid container>
              <Grid item className={classes.instuctionsPaneCenterAlign}>
                <img
                  alt="screenshot"
                  src={GraphDrag}
                  style={{ width: "400px" }}
                />
              </Grid>
              <Grid item>
                <Typography className={classes.instructionText} variant="body2">
                  1. The concentration versus time curves are drawn for the MTX
                  (black line) and creatinine (purple line). The population mean
                  and 95% probability for that dose and infusion time are drawn
                  (green line, green shaded area). The red line is the line for
                  2 standard deviations above the mean. The glucarpidase
                  guideline points are the blue diamonds. Each of these can be
                  toggled off by clicking on the item in the legend.
                  <br />
                  2. The Elimination Threshold box shows when the patient is
                  predicted to reach the thresholds you entered on the Patient
                  Data and Dosing screen. If you didn’t enter anything, it
                  defaults to 0.2 uM and 0.1 uM.
                  <br />
                  3. Highlight a certain time point by typing in the box next to
                  the Highlight button. You may need to scroll down to find the
                  Highlight button. You will need to re-enter any time point if
                  the browser size is adjusted.
                  <br />
                  4. Click and drag the box to move it
                  <br />
                  5. Click the black box to remove it
                  <br />
                  6. Hover your cursor over the curve and click to highlight and
                  add a black box. Click the box to remove it.
                  <br />
                  7. Any boxes shown on the graph will be there when the report
                  is downloaded
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextTabButtonClick}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </div>
      </TabPanel>
      {/* DONE */}
      <TabPanel value={value} index={11}>
        <div className={classes.instuctionsPane}>
          <div className={classes.instructionContentPane}>
            <Typography variant="h6">Download Report or Graph</Typography>
            <Grid container>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={DownloadReportButton}
                  style={{ padding: "10px" }}
                />
                <Typography variant="body2" className={classes.instructionText}>
                  1. To download a PDF report, click the Report button on the
                  right of the graph. It will show the graph as it is on your
                  screen, with the black boxes at certain time points and
                  whatever curves you have enabled.
                </Typography>
              </Grid>
              <Grid item sm className={classes.instGridItem}>
                <img
                  alt="screenshot"
                  src={PlotBurger}
                  width="250px"
                  style={{ padding: "10px" }}
                />
                <Typography variant="body2" className={classes.instructionText}>
                  1. Click the three lines in the top right corner of the graph
                  to enable downloading the image as a PNG, JPEG, SVG, or PDF.
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.instructionNavigation}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevTabButtonClick}
              className={classes.prevButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFinishButtonClick}
              className={classes.nextButton}
            >
              Done
            </Button>
          </div>
        </div>
      </TabPanel>
    </div>
  );
};

export default Instructions;
