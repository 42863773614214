export function getPatientInfoObject() {
  return {
    identifier: "",
    sex: "female",
    weight: 0,
    height: 0,
    metricunits: true,
    creatunitmml: false,
    age: 0,
    race: "",
    ethnicity: "",
    surface: 0,
    indication: "",
    dose: 0,
    sim: 0,
    load: 0,
    loadin: 0,
    main: 0,
    mainin: 0,
    predictioninterval: 95,
    baselinealbumin: false,
    timeInHours: true,
    parameters: [],
    upperconc: 0,
    upperat: 0,
    lowerconc: 0,
    lowerat: 0,
    down: false,
    peff: false,
  };
}
