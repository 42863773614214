import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { grey, blue } from "@material-ui/core/colors";
import BottomNavBar from "./BottomNav";

const useStyles = makeStyles((theme) => ({
  bar: {
    backgroundColor: "#4d82cb",
    color: "white",
    zIndex: theme.zIndex.drawer + 1,
    "& a": {
      color: "#fff",
      textDecoration: "none",
    },
    "& a:hover": {
      color: "#DDD",
    },
  },
  menu: {
    backgroundColor: blue[800],
    color: "white",
  },
  active: {
    borderBottom: "2px solid",
    borderBottomColor: "white",
  },

  auth: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
  },
  marginRight: {
    marginRight: "1rem",
  },

  breadcrumbs: {
    backgroundColor: grey[800],
    padding: ".25rem 1rem",
    color: grey[400],
    "& a": {
      textDecoration: "none",
      color: grey[400],
    },
  },
  breadcrumbActive: {
    color: "white",
  },

  logo: {
    display: "inline-block",
    margin: 0,
    flexGrow: 1,
    "& a": {
      fontSize: "1.75rem",
      fontWeight: 500,
    },
  },

  nav: {
    display: "inline-block",
    margin: 0,
    padding: 0,
    listStyle: "none",

    "& li": {
      display: "inline-block",
      marginLeft: 40,
    },
  },

  navRight: {
    float: "right",
  },
  toolbar: theme.mixins.toolbar,
  menuButton: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },

  navItems: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  navLink: {
    fontSize: "1.5rem",
    fontWeight: 500,
  },
}));

const Header = () => {
  const classes = useStyles();
  const [bottomNavState, setBottomNavState] = React.useState({
    value: 0,
    pathMap: ["/", "/simulation", "/about", "/faq", "/contact"],
  });
  const handleBottomNavStateChange = (event, value) => {
    const newState = {
      ...bottomNavState,
      value,
    };
    setBottomNavState(newState);
  };
  return (
    <React.Fragment>
      <AppBar position="static" className={classes.bar}>
        <Toolbar>
          <Typography variant="h6" className={classes.logo}>
            <Link to="/" color="inherit">
              MTXPK.org
            </Link>
          </Typography>
          <div className={classes.navItems}>
            <ul className={clsx(classes.nav, classes.navRight)}>
              <li>
                <Link
                  to="/simulation"
                  color="inherit"
                  className={classes.navLink}
                >
                  Simulation Tool
                </Link>
              </li>
              <li>
                <Link to="/about" color="inherit" className={classes.navLink}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/faq" color="inherit" className={classes.navLink}>
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/contact" color="inherit" className={classes.navLink}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </Toolbar>
      </AppBar>

      <BottomNavBar
        bottomNavState={bottomNavState}
        handleBottomNavStateChange={handleBottomNavStateChange}
      />
    </React.Fragment>
  );
};

export default Header;
