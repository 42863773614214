import React from "react";
import { Snackbar } from "@material-ui/core";
import NotificationWrapper from "./NotificationWrapper";

const Notification = ({
  notificationOpen,
  variant,
  message,
  handleNotificationClose,
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={notificationOpen}
      autoHideDuration={2000}
      onClose={handleNotificationClose}
    >
      <NotificationWrapper
        onClose={handleNotificationClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
};

export default Notification;
