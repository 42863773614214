import React from "react";
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";

// const useStyles = makeStyles(theme => ({
//   loadDialog: {
//     width: "100%",
//     maxWidth: 300
//   },
//   listItem: {},
//   dialogPaper: {
//     width: 300
//   }
// }));

const InstallPopup = ({ showInstallMessage, handleInstallMessageClose }) => {
  return (
    <Dialog
      open={showInstallMessage}
      onClose={handleInstallMessageClose}
      aria-labelledby="App Installer"
      maxWidth={false}
    >
      <DialogContent>
        <Typography variant="body2">
          To install this webapp on your phone, tap the share button and then
          Add to homescreen.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleInstallMessageClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstallPopup;
