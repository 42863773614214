import React from "react";
import "./App.css";
import Routes from "./Routes";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { MatomoProvider } from "@jonkoops/matomo-tracker-react";
import MatomoInstance from "./Provider";

let theme = createTheme({
  palette: {
    common: { black: "#000", white: "#fff" },

    background: { paper: "#fff", default: "#fafafa" },

    primary: {
      light: "rgba(76, 130, 230, 1)",
      main: "rgba(76, 130, 230, 1)",
      dark: "rgba(33,83,155,1)",
      contrastText: "#fff",
    },

    secondary: {
      light: "rgba(181, 186, 187, 1)",
      main: "rgba(109, 118, 119, 1)",
      dark: "rgba(54, 59, 59, 1)",
      contrastText: "#fff",
    },

    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },

    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MatomoProvider value={MatomoInstance}>
        <CssBaseline />
        <Routes />
      </MatomoProvider>
    </ThemeProvider>
  );
}

export default App;
